<template>
  <el-menu
    :default-active="defaultActive"
    :class="[
      theme == 'holiday' ? 'menu-default' : 'menu',
      { 'white-menu': isWhite },
    ]"
    mode="horizontal"
    @select="handleSelect"
  >
    <template v-for="item in menuList">
      <el-submenu
        v-if="item.children"
        :index="item.path"
      >
        <template slot="title">{{ item.name }}</template>
        <el-menu-item v-for="cItem in item.children" :index="cItem.path">
          {{ cItem.name }}
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :index="item.path">{{ item.name }}</el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { pcMenu } from "../../menu";
export default {
  data () {
    return {
      menuList: pcMenu,
      defaultActive: "",
    };
  },
  props: {
    isWhite: Boolean,
  },
  watch: {
    $route: {
      handler (to, from) {
        const { path, matched } = to;
        const breadcrumbList = matched.map((item) => item.meta.title);
        // 设置面包屑
        this.$store.commit(
          "setBreadcrumbList",
          breadcrumbList.filter((item) => item)
        );
        // 设置激活菜单
        // this.defaultActive = `/${path.split("/").splice(1, 2).join("/")}`;
        this.defaultActive = path;
      },
      immediate: true,
    },
  },
  computed: {
    theme () {
      return this.$store.state.systemTheme;
    }
  },
  methods: {
    handleSelect (key) {
      if (key == 1) {
        this.$router.push({
          path: "/renyiTheatre/capitalTheatre",
          query: {
            id: 5,
          },
        });
      } else if (key == 2) {
        this.$router.push({
          path: "/renyiTheatre/theatreCenter",
          query: {
            id: 7,
          },
        });
      } else if (key == 3) {
        this.$router.push({
          path: "/renyiTheatre/theatreCenter",
          query: {
            id: 8,
          },
        });
      } else if (key == "http://piao.bjry.com/") {
        window.open("http://piao.bjry.com/", "_blank");
      } else {
        this.$router.push(key);
      }
    },
  },
};
</script>

<style lang="less">
.el-menu--popup-bottom-start {
  margin-top: 0.2604vw;
}
.el-menu--popup {
  min-width: 10.4167vw;
  padding: 0.2604vw 0;
}
// .el-menu-item {
//   height: 1.875vw;
//   line-height: 1.875vw;
//   padding: 0.5208vw 0;
// }
// .el-dropdown-menu__item, .el-menu-item {
//   font-size: 0.625vw;
// }
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: #fff;
  float: none;
  height: 1.875vw;
  line-height: 1.875vw;
  padding: 0 0.5208vw;
  color: #909399;
  font-size: 0.7292vw;
}
.header {
  width: 100%;
  height: 4.1667vw;
  &-logo {
    width: 17.3438vw;
    height: 2.0833vw;
  }
  .menu {
    height: 100%;
    border: none;
    font-weight: bold;
    display: flex;
    .el-submenu {
      margin-left: 2.0833vw;
    }
    .el-menu-item {
      font-size: 0.7292vw;
      margin-left: 2.0833vw;
      height: 100%;
      line-height: 4.6875vw;
      padding: 0;
    }
    .el-submenu,
    .el-submenu__title {
      font-size: 0.7292vw;
      height: 100%;
      line-height: 4.6875vw;
      padding: 0;
      box-sizing: border-box;
    }
    .el-submenu__icon-arrow {
      font-size: 0.625vw;
      color: #212122;
      font-weight: bold;
    }
    .is-active {
      color: #f9680d !important;
      border-bottom: 0.1563vw solid #f9680d;
      .el-submenu__icon-arrow {
        color: #f9680d;
      }
      .el-submenu__title {
        color: #f9680d;
        border: none !important;
      }
    }
    .el-submenu__title,
    .el-menu-item {
      color: #212122;
    }
    &.white-menu {
      background-color: transparent;
      .el-submenu__title,
      .el-menu-item,
      .el-submenu__icon-arrow {
        color: #ffffff;
      }

      .el-menu-item:hover,
      .el-submenu__title:hover {
        background-color: transparent;
      }
      // .is-active {
      //   color: #ffffff !important;
      //   border-bottom: 3px solid #ffffff;
      // }
    }
  }
  .menu-default {
    height: 100%;
    border: none;
    font-weight: bold;
    display: flex;
    .el-submenu {
      margin-left: 2.0833vw;
    }
    .el-menu-item {
      margin-left: 2.0833vw;
      height: 100%;
      line-height: 4.6875vw;
      padding: 0;
    }
    .el-submenu,
    .el-submenu__title {
      height: 100%;
      line-height: 4.6875vw;
      padding: 0;
      box-sizing: border-box;
    }
    .el-submenu__icon-arrow {
      color: #212122;
      font-weight: bold;
    }
    .is-active {
      color: #fff !important;
      border-bottom: 0.1563vw solid #fff;
      .el-submenu__icon-arrow {
        color: #fff;
      }
      .el-submenu__title {
        color: #fff;
        border: none !important;
      }
    }
    .el-submenu__title,
    .el-menu-item {
      color: #212122;
    }
    &.white-menu {
      background-color: transparent;
      .el-submenu__title,
      .el-menu-item,
      .el-submenu__icon-arrow {
        color: #ffffff;
      }

      .el-menu-item:hover,
      .el-submenu__title:hover {
        background-color: transparent;
      }
      // .is-active {
      //   color: #ffffff !important;
      //   border-bottom: 3px solid #ffffff;
      // }
    }
  }
}
</style>
