<template>
  <!-- <div class="home"> -->
  <!-- 全屏滚动 -->
  <FullPage :list="pageList" :homeDataAll="homeDataAll" :inited="inited">
  </FullPage>
  <!-- </div> -->
</template>

<script>
import { getHomePortal } from "@/service/home";
import { getArticleDetail } from "@/service/culture";
import FullPage from "./components/FullPage.vue";
// import bg from "@/assets/img/home/bg3.jpg";
// import springBg from "@/assets/img/spring/spring-top.png"
export default {
  components: { FullPage },
  data() {
    return {
      homeDataAll: {},
      pageList: [],
      inited: false,
      urlSearch: {},
    };
  },
  created() {
    this.pageList = [{ id: "bg", image: this.systemThemeInfo.thumbnail }]
  },
  mounted() {
    this.getHomeData();
  },
  updated() {
    if (decodeURIComponent(location.search)) {
      this.urlSearch = this.getUrlParams(
        this.getDataBeforeHash(decodeURIComponent(location.href))
      );
      const { id, level1, level2 } = this.urlSearch;
      if (level1 === "HOME" && level2 === "FWHZ") {
        document
          .getElementById("page-footer")
          .scrollIntoView({ behavior: "smooth" });
      } else if (level1 === "JUMU" && level2 === "YCJM") {
        window.open(`#/performance/detail/${id}`, "_self");
      } else if (level1 === "NEWS" && level2 === "JYDT") {
        this.$router.push({
          path: "/ArtsInformation/detail",
          query: {
            id: id,
            page: "1",
          },
        });
      } else if (level1 === "NEWS" && level2 === "JYGG") {
        this.$router.push({
          path: "/ArtsInformation/detail",
          query: {
            id: id,
            page: "2",
          },
        });
      } else if (level1 === "NEWS" && level2 === "JMTJ") {
        this.$router.push({
          path: "/ArtsInformation/detail",
          query: {
            id: id,
            page: "3",
          },
        });
      } else if (level1 === "NEWS" && level2 === "RYHD") {
        this.$router.push({
          path: "/ArtsInformation/detail",
          query: {
            id: id,
            page: "4",
          },
        });
      } else if (level1 === "RYRS") {
        this.$router.push({
          path: "/PeopleEntertainers/detail",
          query: {
            id: id,
            page: "1",
          },
        });
      } else if (level1 === "JUMU" && level2 === "JMDC") {
        window.open(`#/culture/collection/detail/${id}?from=dc`, "_self");
      } else if (level1 === "culture" && level2 === "book") {
        getArticleDetail(id).then((res) => {
          window.open(
            `${location.origin}${
              location.pathname || "/"
            }static/read.html?url=${encodeURIComponent(
              res.data.source
            )}&pageNum=${res.data.attatchmentUrl}`,
            "_self"
          );
        });
      } else if (level1 === "culture" && level2 === "shop") {
        this.$router.push({
          path: "/culture/periodical/index",
          query: {
            type: level2,
          },
        });
      } else if (level1 === "culture" && level2 === "rywc") {
        this.$router.push({
          path: "/culture/creativity/index",
        });
      } else if (level1 === "culture" && level2 === "video") {
        window.open(`#/culture/video/detail/${id}`, "_self");
      } else if (level1 === "ZN") {
        this.$router.push({
          path: "/culture/guide/index",
        });
      } else if (level1 === "rotation" && level2 === "museum") {
        this.$router.push({
          path: "/theatreMuseum",
        });
      } else if (level1 === "SZ") {
        this.$router.push({
          path: "/theatreMuseum/digitalDetail",
          query: {
            id: id,
          },
        });
      } else if (level1 === "CL") {
        this.$router.push({
          path: "/theatreMuseum/exhibitDetail",
          query: {
            id: id,
          },
        });
      } else if (level1 === "HOME" && level2 === "TJ") {
        return true;
      } else if (level1 === "HOME" && level2 === "DCWJ") {
        window.open(`#/survey/index/${id}`, "_self");
      }
    }
  },
  computed: {
    systemThemeInfo() {
      return this.$store.state.systemThemeInfo;
    }
  },
  methods: {
    getUrlParams(url) {
      // 通过 ? 分割获取后面的参数字符串
      let urlStr = url.split("?")[1];
      // 创建空对象存储参数
      let obj = {};
      // 再通过 & 将每一个参数单独分割出来
      let paramsArr = urlStr.split("&");
      for (let i = 0, len = paramsArr.length; i < len; i++) {
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split("=");
        obj[arr[0]] = arr[1];
      }
      return obj;
    },
    getDataBeforeHash(str) {
      const index = str.indexOf("#");
      if (index === -1) {
        return str;
      } else {
        return str.slice(0, index);
      }
    },
    async getHomeData() {
      try {
        const res = await getHomePortal();
        this.inited = true;
        this.homeDataAll = res.data || {};
        let list =
          res.data.topBanners?.filter((it) => {
            return it.image;
          }) || [];
        // 最多显示7+1
        this.pageList = [{ id: "bg", image: this.systemThemeInfo.thumbnail }, ...list].slice(0, 8);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
