<template>
  <div class="repertoire-collection-page" :style="{ backgroundPosition }">
    <TitleItemVue title="剧目典藏">
      <template slot="operation">
        <div class="header-right-btn" @click="toMore()">
          <div class="header-right-btn-title">查看更多</div>
          <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
          />
        </div>
      </template>
      <template slot="content">
        <div class="content-title-bg">
          <div class="title">PERFORMANCE</div>
          <div class="tips">ART PERFORMANCE —————— 04</div>
        </div>
        <div class="content-detail">
          <div class="content-header">
            <!-- 时间轴 -->
            <div class="line-box">
              <div
                :class="[
                  'line-item',
                  { active: activeValue === item, disabled: item === 1952 },
                ]"
                v-for="(item, index) in dateList"
                :key="index"
                @click="handleDateClick(item)"
              >
                <div class="time">{{ item }}</div>
                <div class="line-dot"></div>
              </div>
            </div>
            <div class="line"></div>
          </div>
          <div class="content-swiper">
            <el-carousel
              ref="collectionCarousel"
              :loop="false"
              :autoplay="false"
              indicator-position="outside"
              class="artist-carousel"
              trigger="click"
              @change="changeCarousel"
            >
              <el-carousel-item
                class="artist-car-item"
                v-for="(list, index) in dataList"
                :key="index"
              >
                <div
                  class="content-item"
                  v-for="item in list"
                  :key="item.id"
                  @click.stop="handleDetail(item)"
                >
                  <c-image class="img" :src="item.image" />
                  <div class="name">{{ item.repertoireName }}</div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="arrow">
              <img
                class="left"
                @click="changeSwiper(swiperIndex - 1)"
                src="@/assets/img/home/swiper-arrow-left-white.png"
              />
              <img
                class="right"
                @click="changeSwiper(swiperIndex + 1)"
                src="@/assets/img/home/swiper-arrow-right-white.png"
              />
            </div>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import TitleItemVue from "./TitleItem.vue";
import { getRepertoirePage2 } from "@/service/performance";
export default {
  components: {
    TitleItemVue,
  },
  data() {
    return {
      dateList: [2022, 2012, 2002, 1992, 1982, 1972, 1962, 1952],
      activeValue: 2022,
      dataList: [],
      swiperIndex: 0,
    };
  },
  props: {
    backgroundPosition: String,
  },
  mounted() {
    this.$store.dispatch("getDict", ["recommed-year"]);
    if (this.yearType.length > 0) {
      this.getList();
    }
  },
  computed: {
    yearType() {
      return this.$store.state.dictMap["recommed-year"] || [];
    },
  },
  watch: {
    yearType() {
      if (this.yearType.length > 0) {
        this.getList();
      }
    },
  },
  methods: {
    handleDateClick(index) {
      if (index === 1952) {
        return;
      }
      this.activeValue = index;
      this.getList();
    },
    toMore() {
      window.open(`#/culture/collection/index`, "_blank");
    },
    groupJsonArray(jsonArray, size) {
      // 解析 JSON 字符串为 JavaScript 数组
      const arr = jsonArray;

      return arr.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }

        acc[chunkIndex].push(curr);

        return acc;
      }, []);
    },
    async getList() {
      let yearsId = (
        this.yearType.find(
          (item) => String(item.value) === String(this.activeValue)
        ) || {}
      ).id;
      const res = await getRepertoirePage2({
        pageNo: 1,
        pageSize: 20,
        condition: {
          isRecommend: 1,
          showTop: 1,
          status: 1,
          yearsId,
        },
      });
      const resTemp = this.groupJsonArray(
        res.data?.data.sort(function (a, b) {
          return (
            new Date(b.showTime).getTime() - new Date(a.showTime).getTime()
          );
        }) || [],
        5
      );
      this.dataList = resTemp;
    },
    changeCarousel(e) {
      this.swiperIndex = e;
    },
    changeSwiper(index) {
      if (index < 0) {
        index = this.dataList.length - 1;
      }
      if (index > this.dataList.length - 1) {
        index = 0;
      }
      this.$refs.collectionCarousel.setActiveItem(index);
    },
    handleDetail(item) {
      window.open(`#/culture/collection/detail/${item.id}?from=dc`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.repertoire-collection-page {
  width: 100%;
  height: 100%;
  background: url("~@/assets/img/home/repertoire-bg.png") no-repeat;
  background-size: cover;
  padding-bottom: 3.5937vw;
  .header-right-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 1.0417vw;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #707070;
      line-height: 1.0417vw;
    }
    .header-right-btn-img {
      margin-left: 0.625vw;
      width: 0.8333vw;
      height: 0.5208vw;
    }
  }

  .content {
    .content-title-bg {
      .title {
        color: rgba(255, 255, 255, 0.6);
      }
      .tips {
        color: #21394b;
      }
    }
    .content-detail {
      position: relative;
      .content-header {
        position: relative;
        padding-top: 1.8229vw;
        box-sizing: border-box;
        .line {
          width: 100%;
          padding: 0 0.1563vw;
          box-sizing: border-box;
          height: 0.0521vw;
          background: #bf805f;
          background-clip: content-box;
          margin-top: -0.9375vw;
        }
        .line-box {
          width: 72.9167vw;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          .line-item {
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            .time {
              font-size: 0.9375vw;
              font-family: AppleSystemUIFont;
              color: #bf805f;
              line-height: 0.9375vw;
              padding-bottom: 0.7813vw;
              position: relative;
              &::after {
                content: "";
                width: 0;
                height: 0;
                position: absolute;
                left: 3.6458vw;
                top: 0.4167vw;
                border-bottom: 0.0521vw dashed #f9680d;
              }
            }
            .line-dot {
              width: 1.875vw;
              height: 1.875vw;
              background-color: #bf805f;
              position: relative;
              border-radius: 50%;
              background-clip: content-box;
              border: 0.4167vw solid rgba(220, 188, 172, 0.5);
              box-sizing: border-box;
            }
            &.disabled {
              cursor: default;
              .line-dot {
                opacity: 0.5;
              }
            }

            &.active {
              .time {
                font-size: 1.0417vw;
                color: #f9680d;
                &::after {
                  width: 5.7292vw;
                  transition: all 1s ease-in-out;
                }
              }
              .line-dot {
                background-color: #ffffff;
                border: 0.4167vw solid rgba(249, 104, 13, 0.5);
                &::before {
                  content: "";
                  width: 2.3438vw;
                  height: 2.1875vw;
                  .background-image("/home/checked-icon.png");
                  background-size: contain;
                  position: absolute;
                  top: 2.1875vw;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
      .content-swiper {
        width: 72.9167vw;
        margin: 0 auto;
        margin-top: 6.0417vw;
        position: relative;
        .artist-carousel {
          ::v-deep(.el-carousel__container) {
            height: 24.4792vw;
          }
          .artist-car-item {
            display: flex;

            .content-item {
              margin-right: 1.0417vw;
              cursor: pointer;
              &:last-child {
                margin-right: 0;
              }
              .img {
                width: 13.5417vw;
                height: 19.2708vw;
                box-shadow: 0 0.1563vw 0.5208vw 0 rgba(0, 0, 0, 0.2);
                border-radius: 0.4167vw;
                overflow: hidden;
                ::v-deep(img) {
                  transition: all 0.3s ease-in-out;
                }
                &:hover {
                  ::v-deep(img) {
                    transform: scale(1.1);
                  }
                }
              }
              .name {
                text-align: center;
                padding-top: 1.0417vw;
                font-size: 0.9375vw;
                font-weight: 500;
                font-family: AppleSystemUIFont;
                color: #ffffff;
                line-height: 0.9375vw;
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  width: 3.125vw;
                  height: 0.0521vw;
                  background: #f2e6d5;
                  left: 50%;
                  top: 2.7083vw;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
