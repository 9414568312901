<!--
 * @Author: zhongxq
 * @Date: 2023-10-21 20:53:07
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-22 21:14:06
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\index.vue
 * @Description:
 *
-->
<template>
    <div class="pc-container">
        <div class="container-header" :class="theme == 'holiday' ? 'system-color-bg' : ''">
            <Header :isWhite="theme == 'holiday' ? true : false" />
        </div>
        <div class="container-main">
            <router-view />
        </div>
    </div>
</template>


<script>
import Header from '@/components/pc/Header'
import Breadcrumb from '@/components/pc/Breadcrumb'
export default {
    name: "Home",
    components: {
        Header, Breadcrumb
    },
    computed: {
    theme() {
      return this.$store.state.systemTheme;
    }
  },
};
</script>

<style lang="less" scoped>
    .pc-container {
        width: 100%;
        height: 100%;
        min-width: 56.25vw;
        .container-header {
            position: sticky;
            z-index: 1001;
            top: 0;
            margin: 0 auto;
            background-color: #fff;
        }
    }
</style>
