<template>
  <div>
    <div class="headerBox" :class="theme == 'holiday' ? 'system-color-bg-color' : ''">
      <div class="topl">
        <img src="@/assets/img/back.svg" class="back" alt="" @click="onBack" />
        <img src="@/assets/img/header-logo-white.png" class="logo" alt="" />
      </div>

      <img
        class="mobileMenu"
        src="@/assets/img/mobileMenu.svg"
        @click="isShowMenu = !isShowMenu"
        ref="term"
      />
      <!-- <el-dropdown @command="onClickMenu">
      <span class="el-dropdown-link">
        <img class="mobileMenu" src="@/assets/img/mobileMenu.svg" />
      </span>
      <el-dropdown-menu class="dropdownBox" slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in menuList"
          :key="index"
          :command="item"
          >{{ item.name }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown> -->
    </div>
    <div v-if="isShowMenu" ref="drop">
      <el-collapse v-if="isShowMenu">
        <div v-for="item in menuList" :key="item.id">
          <el-collapse-item
            v-if="item.children && item.children.length"
            :title="item.name"
          >
            <div
              class="el-collapse-item__header"
              style="padding-left: 0.4rem"
              v-for="child in item.children"
              :key="child.id"
              @click="onClickMenu(child)"
            >
              {{ child.name }}
            </div>
          </el-collapse-item>
          <div
            class="el-collapse-item__header"
            @click="onClickMenu(item)"
            v-else
          >
            {{ item.name }}
          </div>
        </div>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { mobileMenu } from "@/constant/mobileMenu";
export default {
  data() {
    return {
      menuList: mobileMenu,
      isShowMenu: false,
    };
  },
  computed: {
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  mounted() {
    document.addEventListener("click", this.clickOut);
  },
  methods: {
    onClickMenu(item) {
      if (item.name === "演出购票") {
        window.open(item.path, "_self");
      } else {
        this.$router.push(item.path);
      }
      this.isShowMenu = false;
    },
    onBack() {
      this.$router.back();
    },
    // 当点击菜单以外的区域隐藏菜单
    clickOut(e) {
      let drop = this.$refs.drop;
      let term = this.$refs.term;
      if (
        drop &&
        !drop.contains(e.target) &&
        term &&
        !term.contains(e.target) &&
        this.isShowMenu
      ) {
        this.isShowMenu = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
  height: 1.3067rem;
  background-color: #bf805f;
  .topl {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
  }
  .back {
    width: 0.48rem;
    height: 0.48rem;
  }
  .logo {
    width: 5.1333rem;
    height: 0.68rem;
    margin-left: 0.2rem;
  }
  .mobileMenu {
    width: 0.7467rem;
    height: 0.7467rem;
    margin-right: 0.4rem;
  }
}

::v-deep .el-dropdown-menu__item {
  width: 2rem;
  height: 0.6667rem;
  text-align: center;
  font-size: 0.4rem;
  line-height: 0.6667rem;
}
::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #f9680d;
}

::v-deep .el-collapse {
  width: 100%;
  font-size: 0.4rem;
}
::v-deep .el-collapse-item__header {
  height: 0.6667rem;
  font-size: 0.4rem;
  padding: 0.1333rem 0.4rem;
}
::v-deep .el-collapse-item__content {
  font-size: 0.4rem;
}
</style>
