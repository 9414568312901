<!--
 * @Author: zhongxq
 * @Date: 2023-10-22 22:01:16
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 17:13:14
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\home\components\DanMu.vue
 * @Description:
 *
-->
<template>
  <vue-danmaku
    ref="danmaku"
    :danmus="danmus"
    use-slot
    loop
    :speeds="100"
    :channels="danmus.length > 6 ? 2 : 1"
    :top="15"
    style="position: initial"
  >
    <!-- 弹幕插槽（vue 2.6.0 及以上版本可使用 v-slot:dm="{ index, danmu }"语法） -->
    <template slot="dm" slot-scope="{ index, danmu }">
      <div class="dm-item" @click="toDetail(danmu)">
        <div class="dm-tips" :class="theme == 'holiday' ? 'system-color-bg' : ''">NEWS</div>
        <div class="dm-txt">{{ danmu.title }}</div>
      </div>
    </template>
    <!-- 容器插槽 -->
    <div></div>
  </vue-danmaku>
</template>

<script>
import vueDanmaku from "vue-danmaku";
import { portalHit } from "@/service/ArtsInformation";

export default {
  components: {
    vueDanmaku,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    page: Number,
  },
  computed: {
    danmus() {
      return this.list;
    },
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  watch: {
    page() {
      if (this.page === 1) {
        this.$refs.danmaku?.play();
        this.$refs.danmaku?.show();
      } else {
        this.$refs.danmaku?.pause();
        this.$refs.danmaku?.hide();
      }
    },
  },
  data() {
    return {
      // danmus: [
      //   { title: "售票预告|" },
      //   { title: "售票预告|《萨法翻山倒海发发火是的合适的话方式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒海发发火是的合适的话方式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒的话方式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒海发发火是的合适的话方式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒海式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒海发发火是的合适的话方式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒海发发》" },
      //   { title: "售票预告|《萨法翻山倒海发发火是的合适的话方式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒海发发火是的合适的话方式好客山东》" },
      //   { title: "售票预告|《萨法翻山方式好客山东》" },
      //   { title: "售票预告|《萨法翻山倒海发发火是的合适的话方式好客山东》" },
      // ],
    };
  },
  methods: {
    toDetail(item) {
      portalHit(item.id);
      if (item.sourceUrl) {
        window.open(item.sourceUrl, "_blank");
      } else if (item.surveyId) {
        // 问卷调查
        window.open(`#/survey/index/${item.surveyId}`, "_blank");
      } else {
        // 文章
        window.open(`#/ArtsInformation/detail?id=${item.id}`, "_blank");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.dm-item {
  display: flex;
  align-items: center;
  padding: 0.2604vw;
  background: rgba(33, 66, 75, 0.3);
  border-radius: 1.0417vw;
  box-sizing: border-box;
  cursor: pointer;
  .dm-tips {
    font-size: 0.8333vw;
    color: #fff;
    background: #f9680d;
    border-radius: 1.0417vw;
    padding: 0.3646vw 0.625vw;
    box-sizing: border-box;
  }
  .dm-txt {
    display: inline-block;
    padding-right: 2.2917vw;
    padding-left: 1.1458vw;
    font-size: 0.9375vw;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #fff;
    line-height: 1.4063vw;
    box-sizing: border-box;
  }
}
</style>
