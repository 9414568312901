<template>
  <div class="category-title">
    <div class="header">
      <div class="title" @click="handleDetail">
        <img
          v-if="title === '人艺资讯'"
          :src="renyizixun"
          class="img"
        />
        <img
          v-else-if="title === '人艺演出'"
          src="@/assets/img/home/performance-title.png"
          class="img"
        />
        <img
          v-else-if="title === '剧目典藏'"
          src="@/assets/img/home/repertoire-title.png"
          class="img"
        />
        <img
          v-else-if="title === '人艺视界'"
          src="@/assets/img/home/arts-title.png"
          class="img"
        /><img
          v-else-if="title === '人艺人'"
          src="@/assets/img/home/artist-title.png"
          class="img small"
        />
        <!-- <div class="title-en">{{ entitle }}</div>
        <div class="title-ch">{{ title }}</div> -->
      </div>
      <div class="operation">
        <slot name="operation"></slot>
      </div>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
    <div class="bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    entitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      renyizixun: null
    }
  },
  computed: {
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  created() {
    this.renyizixun = this.theme == 'holiday' ? require('@/assets/img/spring/renyizixun.png') : require('@/assets/img/home/new-center-title.png')
  },
  methods: {
    handleDetail() {
      let url = "";
      if (this.title === "人艺资讯") {
        url = `/ArtsInformation/dynamic`;
      } else if (this.title === "人艺演出") {
        url = `/performance/index`;
      } else if (this.title === "剧目典藏") {
        url = `/culture/collection/index`;
      } else if (this.title === "人艺视界") {
        url = `/culture/video/index`;
      } else if (this.title === "人艺人") {
        url = `/PeopleEntertainers/founder`;
      }
      const { href } = this.$router.resolve({
        path: url,
      });
      href && window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.category-title {
  position: relative;
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 72.9167vw;
    margin: 0 auto;
    padding-top: 10.4948vh;
    box-sizing: content-box;
    // padding: 70px 260px 90px 260px;
    .title {
      cursor: pointer;
    }
    .img {
      width: 13.8021vw;
      height: 3.4375vw;
    }
    .small {
      width: 11.5625vw;
    }
    .operation {
    }
  }
  .content {
    width: 72.9167vw;
    margin: auto;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    width: 72.9167vw;
    margin: 1.0417vw auto;
  }
}
</style>
