var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-center-page"},[_c('TitleItemVue',{attrs:{"title":"人艺资讯"}},[_c('template',{slot:"operation"},[_c('div',{staticClass:"header-right-btn",on:{"click":function($event){return _vm.toDetail()}}},[_c('div',{staticClass:"header-right-btn-title"},[_vm._v("查看更多")]),_c('img',{staticClass:"header-right-btn-img",attrs:{"src":require("@/assets/img/home/to-more-icon.png")}})])]),_c('template',{slot:"content"},[_c('div',{staticClass:"content-detail"},[_c('div',{staticClass:"content-top"},[_c('swiper',{ref:"swiperTop",staticClass:"center-carousel",attrs:{"options":_vm.swiperOptionTop}},[_vm._l((_vm.newList.referralTop),function(item){return _c('swiper-slide',{key:item.id},[_c('c-image',{staticStyle:{"width":"100%","height":"100%","cursor":"pointer"},attrs:{"src":item.image},on:{"click":function($event){return _vm.handleDetail(item)}}}),_c('div',{staticClass:"swiper-tips"},[_c('div',{staticClass:"swiper-desc"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1),_c('div',{staticClass:"content-right"},_vm._l((_vm.newList.referralRight),function(item,index){return _c('div',{key:index,class:`content-right-item${index} content-right-item`,on:{"click":function($event){return _vm.handleDetail(item)}}},[_c('div',{staticClass:"content-right-top"},[(
                  item.level2 === '剧院公告' &&
                  (item.image.includes('jpg') || item.image.includes('png'))
                )?_c('c-image',{staticClass:"img",attrs:{"src":item.image}}):(
                  item.level2 === '剧院公告' &&
                  !(item.image.includes('jpg') || item.image.includes('png'))
                )?_c('div',{staticClass:"jygg"},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('c-image',{staticClass:"img",attrs:{"src":item.image}}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(item.name))])],1),_c('div',{staticClass:"content-right-bottom"},[_c('div',{staticClass:"title-header"},[_c('img',{staticClass:"img",attrs:{"src":item.level2 === '剧院动态'
                      ? require('@/assets/img/home/new-center-icon3.png')
                      : require('@/assets/img/home/new-center-icon2.png')}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.level2))])]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.dayjs(item.targetCreateTime).format("YYYY-MM-DD"))+" ")])])])}),0),_c('div',{staticClass:"content-bottom"},_vm._l((_vm.newList.referralLeft),function(item,index){return _c('div',{key:index,class:`content-bottom-item${index} content-bottom-item`,on:{"click":function($event){return _vm.handleDetail(item)}}},[_c('div',{staticClass:"content-bottom-left"},[_c('div',{staticClass:"txt"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"title-header"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/home/new-center-icon1.png")}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.level2))])])]),_c('div',{staticClass:"content-bottom-right"},[_c('c-image',{staticClass:"img",attrs:{"src":item.image}})],1)])}),0)])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }