<template>
  <div class="new-center-page" :class="[theme == 'holiday' ? 'system-color-bg' : '', theme == 'holiday' ? 'system-zhuzi' : '']">
    <TitleItemVue title="人艺资讯">
      <template slot="operation">
        <div class="header-right-btn" @click="toDetail()">
          <div class="header-right-btn-title" :class="theme == 'holiday' ? 'system-color-text-y' : ''">查看更多</div>
          <template>
            <span v-if="theme == 'holiday'" class="system-color-text-y" style="font-size: 1.0417vw;margin-left: 0.7813vw;">→</span>
            <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
            v-else
          />
          </template>
        </div>
      </template>
      <template slot="content">
        <div class="content-detail">
          <div class="content-title-bg">
            <div class="title" :class="theme == 'holiday' ? 'title-spring' : ''">NEWS CENTER</div>
            <div class="tips" :class="theme == 'holiday' ? 'tips-spring' : ''">NEWS CENTER —————— 01</div>
          </div>
          <div class="center">
            <div class="content-left">
              <div class="content-left-top">
                <el-carousel
                  ref="carousel"
                  arrow="never"
                  class="center-carousel"
                  trigger="click"
                  @change="changeCarousel"
                >
                  <el-carousel-item
                    v-for="item in newList.referralTop"
                    :key="item.id"
                  >
                    <c-image
                      :src="item.image"
                      style="
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        border-radius: 0.4167vw;
                        object-fit: cover;
                      "
                      @click="handleDetail(item)"
                    />
                  </el-carousel-item>
                </el-carousel>
                <div class="swiper-tips">
                  <div class="swiper-desc">
                    {{ newList.referralTop[swiperIndex]?.name }}
                  </div>

                  <div class="arrow">
                    <img
                      class="left"
                      @click="changeSwiper(swiperIndex - 1)"
                      src="@/assets/img/home/center-left-icon.png"
                    />
                    <img
                      class="right"
                      @click="changeSwiper(swiperIndex + 1)"
                      src="@/assets/img/home/center-right-icon.png"
                    />
                  </div>
                </div>
              </div>
              <div class="content-left-bottom">
                <div
                  :class="`content-left-bottom-item${index} content-left-bottom-item`"
                  v-for="(item, index) in newList.referralLeft"
                  :key="index"
                  @click="handleDetail(item)"
                >
                  <div class="content-left-bottom-left">
                    <div class="title-header">
                      <img
                        class="img"
                        :src="
                          icons[item.level2] ||
                          require('@/assets/img/home/new-center-icon1.png')
                        "
                      />
                      <div class="title">{{ item.level2 }}</div>
                    </div>
                    <div class="txt">{{ item.name }}</div>
                    <div class="time">
                      {{ dayjs(item.targetCreateTime).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                  <div class="content-left-bottom-right">
                    <c-image :src="item.image" class="img" />
                  </div>
                </div>
              </div>
            </div>
            <div class="content-right">
              <div
                :class="`content-right-item${index} content-right-item`"
                v-for="(item, index) in newList.referralRight"
                :key="index"
                @click="handleDetail(item)"
              >
                <div class="content-right-top">
                  <c-image
                    v-if="
                      item.level2 === '剧院公告' &&
                      (item.image.includes('jpg') || item.image.includes('png'))
                    "
                    :src="item.image"
                    class="img"
                  />
                  <div
                    class="jygg"
                    v-else-if="
                      item.level2 === '剧院公告' &&
                      !(
                        item.image.includes('jpg') || item.image.includes('png')
                      )
                    "
                  >
                    {{ item.name }}
                  </div>
                  <c-image v-else :src="item.image" class="img" />
                  <div class="txt">{{ item.name }}</div>
                </div>

                <div class="content-right-bottom">
                  <div class="title-header">
                    <img
                      class="img"
                      :src="
                        icons[item.level2] ||
                        require('@/assets/img/home/new-center-icon2.png')
                      "
                    />
                    <div class="title">{{ item.level2 }}</div>
                  </div>
                  <div class="time">
                    {{ dayjs(item.targetCreateTime).format("YYYY-MM-DD") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import dayjs from "dayjs";
import TitleItemVue from "./TitleItem.vue";
export default {
  components: {
    TitleItemVue,
  },
  data() {
    return {
      swiperIndex: 0,
      dayjs,
      icons: {
        剧院动态: require("@/assets/img/home/new-center-icon3.png"),
        剧院公告: require("@/assets/img/home/new-center-icon2.png"),
        剧目推荐: require("@/assets/img/home/new-center-icon1.png"),
        剧目推介: require("@/assets/img/home/new-center-icon1.png"),
      },
    };
  },
  props: {
    list: {
      type: Object,
      default() {},
    },
    backgroundPosition: String,
  },
  methods: {
    changeCarousel(e) {
      this.swiperIndex = e;
    },
    changeSwiper(index) {
      if (index < 0) {
        index = this.newList.referralTop.length - 1;
      }
      if (index > this.newList.referralTop.length - 1) {
        index = 0;
      }
      this.$refs.carousel.setActiveItem(index);
    },
    toDetail() {
      window.open(`#/ArtsInformation/dynamic`, "_blank");
    },
    toBuy() {
      window.open("http://piao.bjry.com", "_blank");
    },
    handleDetail(item) {
      // 文章
      item.targetId &&
        window.open(`#/ArtsInformation/detail?id=${item.targetId}`, "_blank");
    },
  },
  computed: {
    newList() {
      const obj = this.list || {};
      obj.referralLeft = obj.referralLeft || [];
      obj.referralTop = obj.referralTop || [];
      obj.referralRight = obj.referralRight || [];
      return obj;
    },
    theme() {
      return this.$store.state.systemTheme;
    }
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.new-center-page {
  width: 100%;
  // min-height: 100vh;
  background-image: url("~@/assets/img/home/new-center-bg-right.png"),
    url("~@/assets/img/home/new-center-bg-left.png");
  background-position: bottom left, bottom right;
  background-repeat: no-repeat;
  background-size: 8% auto, 8% auto;
  padding-bottom: 3.125vw;
  .header-right-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 1.0417vw;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #707070;
      line-height: 1.0417vw;
    }
    .header-right-btn-img {
      margin-left: 0.625vw;
      width: 0.8333vw;
      height: 0.5208vw;
    }
  }
  .content-detail {
    padding-top: 1.4583vw;
    .content-img {
      width: 8.4896vw;
      height: 43.0729vw;
    }
    .content-title-bg {
      .title {
        color: #efdfd7;
        opacity: 0.3;
      }
      .title-spring {
        color: #ffbd9c !important;
        opacity: 0.6 !important;
      }
      .tips {
        color: #f9680d;
      }
      .tips-spring {
        color: #f8d39f !important;
      }
    }
    .center {
      // padding: 29px 260px 90px 260px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .content-left {
        width: 35.4167vw;
        margin-right: 1.0417vw;
        .content-left-top {
          position: relative;
          width: 35.4167vw;
          height: 18.75vw;
          border-radius: 0.4167vw;
          margin-right: 1.0417vw;
          margin-bottom: 1.0417vw;
          .center-carousel {
            height: 18.75vw;
          }
          ::v-deep(.el-carousel__container) {
            height: 18.75vw;
          }
          .swiper-tips {
            width: 35.4167vw;
            height: 4.6875vw;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 0 0 0.4167vw 0.4167vw;
            padding: 0.7813vw 1.5625vw 0.625vw 1.5625vw;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .swiper-desc {
              font-size: 1.25vw;
              font-family: AppleSystemUIFont;
              color: #ffffff;
              line-height: 1.3542vw;
              text-align: left;
              .text-overflow(1);
            }
            .arrow {
              flex-shrink: 0;
              display: flex;
              justify-content: space-between;
              .left {
                width: 0.7292vw;
                height: 0.8333vw;
                cursor: pointer;
              }
              .right {
                width: 0.7292vw;
                height: 0.8333vw;
                cursor: pointer;
              }
            }
          }
        }
        .content-left-bottom {
          width: 35.4167vw;
          display: flex;
          flex-wrap: wrap;

          .content-left-bottom-item {
            width: 17.1875vw;
            height: 8.5938vw;
            background: #f0f5de;
            border-radius: 0.4167vw;
            display: flex;
            padding: 0.9375vw 1.0417vw 0.8854vw;
            box-sizing: border-box;
            margin-bottom: 1.5625vw;
            cursor: pointer;
            &.content-left-bottom-item0 {
              background: #f5dede;
              margin-right: 1.0417vw;
            }
            &.content-left-bottom-item2 {
              margin-right: 1.0417vw;
            }
            &.content-left-bottom-item3 {
              background: #dee9f6;
            }
            .content-left-bottom-left {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 8.8542vw;
              font-size: 1.25vw;
              font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: bold;
              color: #000000;
              line-height: 1.5625vw;
              text-align: left;
              .title-header {
                display: flex;
                align-items: center;
                font-weight: bold;
                .img {
                  width: 1.1458vw;
                  height: 0.9896vw;
                  object-fit: cover;
                }
                .title {
                  text-align: left;
                  font-size: 0.7292vw;
                  line-height: 0.8333vw;
                  font-family: AppleSystemUIFont;
                  color: #000000;
                  padding-left: 0.4167vw;
                }
              }
              .txt {
                flex: 1;
                height: 3.4375vw;
                margin-top: 0.7292vw;
                font-size: 0.9375vw;
                font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
                font-weight: 800;
                text-align: justify;
                color: #000000;
                line-height: 1.1458vw;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; /* 这里是超出几行省略 */
                overflow: hidden;
              }
              .time {
                font-size: 0.7292vw;
                font-family: AppleSystemUIFont;
                color: #717171;
                line-height: 0.7292vw;
                margin-top: 0.8854vw;
              }
            }
            .content-left-bottom-right {
              flex-shrink: 0;
              width: 5.2083vw;
              height: 6.7708vw;
              border-radius: 0.2083vw;
              margin-left: 1.0417vw;
              .img {
                width: 5.2083vw;
                height: 6.7708vw;
                border-radius: 0.2083vw;
                object-fit: cover !important;
              }
            }
          }
        }
      }
      .content-right {
        width: 36.4583vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .content-right-item {
          width: 17.7083vw;
          height: 18.75vw;
          background: #f2e6d5;
          border-radius: 0.4167vw;
          margin-bottom: 1.0417vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          .content-right-top {
            width: 17.7083vw;
            height: 10.4167vw;
            border-radius: 0.4167vw 0.4167vw 0 0;
            .jygg {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 17.7083vw;
              height: 10.4167vw;
              border-radius: 0.4167vw 0.4167vw 0 0;
              background: url("../../../../assets/img/home/jygg.jpg") no-repeat;
              background-size: contain;
              font-size: 1.25vw;
              font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: 800;
              color: #fff;
              padding: 1.0417vw;
              box-sizing: border-box;
            }
            .img {
              width: 17.7083vw;
              height: 10.4167vw;
              border-radius: 0.4167vw 0.4167vw 0 0;
              cursor: pointer;
              object-fit: cover;
            }
            .txt {
              text-align: left;
              padding: 1.0417vw;
              height: 5.7292vw;
              width: 17.7083vw;
              font-size: 1.25vw;
              font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: 800;
              color: #000000;
              line-height: 1.5625vw;
              text-align: justify;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3; /* 这里是超出几行省略 */
              overflow: hidden;
              box-sizing: border-box;
            }
          }
          .content-right-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1.0417vw 0.625vw 1.0417vw;
            box-sizing: border-box;
            font-weight: bold;
            .title-header {
              display: flex;
              align-content: center;
              .img {
                width: 0.9375vw;
                height: 0.9375vw;
                object-fit: cover;
              }
              .title {
                font-size: 0.7292vw;
                font-family: AppleSystemUIFont;
                color: #000000;
                margin-left: 0.4167vw;
              }
            }
            .time {
              font-size: 0.7292vw;
              font-family: AppleSystemUIFont;
              color: #717171;
            }
          }
        }
      }
    }
  }
}
</style>
