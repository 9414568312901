<template>
  <div class="repertoire-collection-page" :style="{ backgroundPosition }">
    <TitleItemVue title="剧目典藏">
      <template slot="operation">
        <div class="header-right-btn" @click="toMore()">
          <div class="header-right-btn-title">查看更多</div>
          <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
          />
        </div>
      </template>
      <template slot="content">
        <div class="content-detail">
          <div class="content-header">
            <!-- 时间轴 -->
            <div class="line-box">
              <div
                :class="[
                  'line-item',
                  { active: activeValue === item, disabled: item === 1952 },
                ]"
                v-for="(item, index) in dateList"
                :key="index"
                @click="handleDateClick(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="content-swiper">
            <div
              class="content-item"
              v-for="item in dataList.slice(0, 2)"
              :key="item.id"
              @click.stop="handleDetail(item)"
            >
              <c-image class="img" :src="item.image" />
              <div class="name">{{ item.repertoireName }}</div>
            </div>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import TitleItemVue from "./TitleItem.vue";
import { getRepertoirePage2 } from "@/service/performance";
export default {
  components: {
    TitleItemVue,
  },
  data() {
    return {
      dateList: [2022, 2012, 2002, 1992, 1982, 1972, 1962, 1952],
      activeValue: 2022,
      dataList: [],
      swiperIndex: 0,
    };
  },
  props: {
    backgroundPosition: String,
  },
  mounted() {
    this.$store.dispatch("getDict", ["recommed-year"]);
  },
  computed: {
    yearType() {
      return this.$store.state.dictMap["recommed-year"] || [];
    },
  },
  watch: {
    yearType: {
      handler() {
        if (this.yearType.length > 0) {
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleDateClick(index) {
      if (index === 1952) return;
      this.activeValue = index;
      this.getList();
    },
    toMore() {
      window.open(`#/culture/collection/index`, "_self");
    },
    groupJsonArray(jsonArray, size) {
      // 解析 JSON 字符串为 JavaScript 数组
      const arr = jsonArray;

      return arr.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }

        acc[chunkIndex].push(curr);

        return acc;
      }, []);
    },
    async getList() {
      let yearsId = (
        this.yearType.find(
          (item) => String(item.value) === String(this.activeValue)
        ) || {}
      ).id;
      const res = await getRepertoirePage2({
        pageNo: 1,
        pageSize: 20,
        condition: {
          isRecommend: 1,
          showTop: 1,
          status: 1,
          yearsId,
        },
      });
      this.dataList =
        res.data?.data.sort(function (a, b) {
          return (
            new Date(b.showTime).getTime() - new Date(a.showTime).getTime()
          );
        }) || [];
    },
    handleDetail(item) {
      window.open(`#/culture/collection/detail/${item.id}?from=dc`, "_self");
    },
  },
};
</script>

<style lang="less" scoped>
.repertoire-collection-page {
  width: 100%;
  background: url("~@/assets/img/home/repertoire-bg-mobile.png") no-repeat;
  background-size: cover;
  padding: 0 30/75rem;
  box-sizing: border-box;

  .header-right-btn {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4/75rem;
    padding: 9/75rem 16/75rem;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 22/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #212122;
      line-height: 33/75rem;
    }
    .header-right-btn-img {
      margin-left: 12/75rem;
      width: 16/75rem;
      height: 10/75rem;
    }
  }

  .content-detail {
    position: relative;
    padding-bottom: 30/75rem;
    .content-header {
      position: relative;
      padding-top: 0/75rem;
      box-sizing: border-box;
      .line-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        // padding-bottom: 20/75rem;
        border-bottom: 1/75rem solid #e8e7e7;
        &::-webkit-scrollbar {
          display: none;
        }
        .line-item {
          font-size: 30/75rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #bf805f;
          line-height: 45/75rem;
          margin-right: 46/75rem;
          padding-bottom: 20/75rem;
          &.disabled {
            opacity: 0.5;
          }
          &.active {
            font-weight: 800;
            color: #212122;
            border-bottom: 3/75rem solid #212122;
          }
        }
      }
    }
    .content-swiper {
      width: 100%;
      min-height: 530/75rem;
      margin: 0 auto;
      margin-top: 50/75rem;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .content-item {
        margin-bottom: 30/75rem;

        cursor: pointer;
        .img {
          width: 336/75rem;
          height: 470/75rem;
          box-shadow: 0/75rem 3/75rem 10/75rem 0/75rem rgba(0, 0, 0, 0.2);
          border-radius: 8/75rem;
        }
        .name {
          text-align: left;
          padding-top: 30/75rem;
          font-size: 30/75rem;
          font-family: AppleSystemUIFont;
          color: #212122;
          line-height: 45/75rem;
          font-weight: 800;
        }
      }
    }
  }
}
</style>
