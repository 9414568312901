<template>
  <div>
    <div class="mobile-tab-icon">
      <div
        class="tab-item"
        v-for="(item, index) in list"
        :key="index"
        @click="toDetail(item.toUrl)"
      >
        <div class="tab-icon">
          <c-image :src="item.image" class="img" />
        </div>
        <div class="tab-title">{{ item.title }}</div>
      </div>
    </div>
    <!-- <div class="mobile-tab-icon">
      <div
        class="tab-item"
        v-for="(item, index) in list.slice(4, 8)"
        :key="index"
        @click="toDetail(item.toUrl)"
      >
        <div class="tab-icon">
          <c-image :src="item.image" class="img" />
        </div>
        <div class="tab-title">{{ item.title }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: "演出购票",
          image: '',
          toUrl: "http://piao.bjry.com/",
        },
        {
          title: "正在演出",
          image: '',
          toUrl: "#/performance/index",
        },
        {
          title: "人艺资讯",
          image: '',
          toUrl: "#/ArtsInformation/dynamic",
        },
        {
          title: "戏剧博物馆",
          image: '',
          toUrl: "#/theatreMuseum",
        },
        {
          title: "人艺概览",
          image: '',
          toUrl: "#/introduction",
        },
        {
          title: "人艺剧场",
          image: '',
          toUrl: "#/renyiOverView/capitalTheatre",
        },
        {
          title: "人艺人",
          image: '',
          toUrl: "#/PeopleEntertainers",
        },
        {
          title: "人艺文化",
          image: '',
          toUrl: "#/culture",
        },
      ],
    };
  },
  props: {},
  computed: {
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  mounted() {
    if(this.theme == 'holiday') {
      // this.list[0].image = require("@/assets/img/spring/s1.png");
      // this.list[1].image = require("@/assets/img/spring/s2.png");
      // this.list[2].image = require("@/assets/img/spring/s3.png");
      // this.list[3].image = require("@/assets/img/spring/s4.png");
      // this.list[4].image = require("@/assets/img/spring/s5.png");
      // this.list[5].image = require("@/assets/img/spring/s6.png");
      // this.list[6].image = require("@/assets/img/spring/s7.png");
      // this.list[7].image = require("@/assets/img/spring/s8.png");
      this.list[0].image = require("@/assets/img/home_mobile/i1.png");
      this.list[1].image = require("@/assets/img/home_mobile/i2.png");
      this.list[2].image = require("@/assets/img/home_mobile/i3.png");
      this.list[3].image = require("@/assets/img/home_mobile/i4.png");
      this.list[4].image = require("@/assets/img/home_mobile/i5.png");
      this.list[5].image = require("@/assets/img/home_mobile/i6.png");
      this.list[6].image = require("@/assets/img/home_mobile/i7.png");
      this.list[7].image = require("@/assets/img/home_mobile/i8.png");
    }else {
      this.list[0].image = require("@/assets/img/home_mobile/i1.png");
      this.list[1].image = require("@/assets/img/home_mobile/i2.png");
      this.list[2].image = require("@/assets/img/home_mobile/i3.png");
      this.list[3].image = require("@/assets/img/home_mobile/i4.png");
      this.list[4].image = require("@/assets/img/home_mobile/i5.png");
      this.list[5].image = require("@/assets/img/home_mobile/i6.png");
      this.list[6].image = require("@/assets/img/home_mobile/i7.png");
      this.list[7].image = require("@/assets/img/home_mobile/i8.png");
    }
  },
  methods: {
    //跳转
    toDetail(url) {
      url && window.open(url, "_self");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.mobile-tab-icon {
  width: 100%;
  display: flex;
  // justify-content: space-around;
  flex-wrap: wrap;
  padding: 30/75rem 10/75rem 0;
  box-sizing: border-box;
  .tab-item {
    width: 25%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20/75rem;
    .tab-icon {
      width: 140/75rem;
      height: 140/75rem;
      // background: #21424b;
      box-sizing: border-box;
      border-radius: 50%;
      .img {
        width: 140/75rem;
        height: 140/75rem;
        border-radius: 50%;
      }
    }
    .tab-title {
      font-size: 24/75rem;
      // margin-top: 5/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333;
    }
  }
}
</style>
