<template>
  <div class="artist-page" :style="{ backgroundPosition }">
    <TitleItemVue title="人艺人">
      <template slot="operation">
        <div class="operation">
          <div
            :class="`operation-item ${
              activeTab === item.value ? 'active' : ''
            }`"
            v-for="item in tabList"
            :key="item.value"
            @click="changeTab(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </template>
      <template slot="content">
        <div class="content-title-bg">
          <div class="title">ONE ARTIST</div>
          <div class="tips">ONE ARTIST —————— 05</div>
        </div>
        <div class="content-detail">
          <div class="artist-carousel">
            <div
              :class="[
                'artist-car-item',
                {
                  'no-scroll': allList.length <= 5,
                  flexend: allList.length <= 4,
                },
              ]"
              :style="{
                width: allList.length * 1.4792 + 'rem',
                animationDuration: `${allList.length * 2}s`,
              }"
              :key="key"
            >
              <div
                v-for="item in allList"
                :key="item.id"
                class="artist-card-item"
                @click="toDetail(item)"
              >
                <c-image class="img" :src="item.image" errorType="artist" />
                <div class="title">{{ item.name }}</div>
                <div class="desc">{{ activeName }}</div>
              </div>
            </div>
            <div
              v-if="allList.length > 5"
              class="artist-car-item scroll-list1"
              :style="{
                width: allList.length * 1.4792 + 'rem',
                animationDuration: `${allList.length * 2}s`,
              }"
              :key="key + 1"
            >
              <div
                v-for="item in allList"
                :key="item.id"
                class="artist-card-item"
                @click="toDetail(item)"
              >
                <c-image class="img" :src="item.image" />
                <div class="title">{{ item.name }}</div>
                <div class="desc">{{ activeName }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="bottom">
        <div class="header-right-btn" @click="toMore()">
          <div class="header-right-btn-title">查看更多</div>
          <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
          />
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import TitleItemVue from "./TitleItem.vue";
import { clickEntertainer } from "@/service/PeopleEntertainers";
export default {
  components: {
    TitleItemVue,
  },
  data() {
    return {
      activeTab: "founder",
      tabList: [
        {
          title: "创始人",
          value: "founder",
        },
        // {
        //   title: "编剧",
        //   value: "screenwriter",
        // },
        // {
        //   title: "导演",
        //   value: "director",
        // },
        // {
        //   title: "设计",
        //   value: "design",
        // },
        {
          title: "演员",
          value: "actor",
        },
        // {
        //   title: "舞美",
        //   value: "choreographer",
        // },
      ],
      activeName: "创始人",
      swiperIndex: 0,
      key: "founder",
    };
  },
  props: {
    list: {
      type: Object,
      default() {},
    },
    backgroundPosition: String,
  },
  computed: {
    dataList() {
      let obj = {};
      Object.keys(this.list).forEach((key) => {
        obj[key] = this.groupJsonArray(this.list[key], 5);
      });
      return obj;
    },
    allList() {
      return this.list[this.activeTab] || [];
    },
  },
  mounted() {},
  methods: {
    toMore() {
      const { href } = this.$router.resolve({
        path: `/PeopleEntertainers/founder`,
      });
      href && window.open(href, "_blank");
    },
    groupJsonArray(jsonArray, size) {
      // 解析 JSON 字符串为 JavaScript 数组
      const arr = jsonArray;

      return arr.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }

        acc[chunkIndex].push(curr);

        return acc;
      }, []);
    },
    changeTab(item) {
      if (item.value !== this.activeTab) {
        this.key = `${item.value}-${+new Date()}`;
      }
      this.activeTab = item.value;
      this.activeName = item.title;
      // window.open(`#/PeopleEntertainers/${item.value}`, "_blank");
    },
    changeCarousel(e) {
      this.swiperIndex = e;
    },
    changeSwiper(index) {
      if (index < 0) {
        index = this.dataList.length - 1;
      }
      if (index > this.dataList.length - 1) {
        index = 0;
      }
      this.$refs.carousel.setActiveItem(index);
    },
    toDetail(item) {
      clickEntertainer(item.targetId);
      window.open(`#/PeopleEntertainers/detail?id=${item.targetId}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll1 {
  0% {
    transform: translateX(100%);
  }
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.artist-page {
  width: 100%;
  background: url("~@/assets/img/home/artist-bg.png") no-repeat;
  background-size: cover;
  padding-bottom: 14.6354vw;
  box-sizing: border-box;
  .header-right-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 1.0417vw;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #707070;
      line-height: 1.0417vw;
    }
    .header-right-btn-img {
      margin-left: 0.625vw;
      width: 0.8333vw;
      height: 0.5208vw;
    }
  }
  .operation {
    display: flex;
    .operation-item {
      font-size: 0.9375vw;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #666666;
      line-height: 0.9375vw;
      padding: 0 2.0833vw;
      padding-bottom: 1.0417vw;
      border-bottom: 0.0521vw solid #b0b0b0;
      position: relative;
      cursor: pointer;
      &.active {
        color: #f9680d;
        border-bottom: 1px solid #f9680d;
      }
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 16px;
        background: #b0b0b0;
        right: 0;
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }
  ::v-deep(.category-title) {
    .header {
      padding-top: 5.4688vw;
    }
  }
  .content {
    .content-title-bg {
      top: 5.4688vw;
      .title {
        color: rgba(191, 128, 95, 0.5);
      }
      .tips {
        color: #707070;
      }
    }
    .content-detail {
      width: 72.9167vw;
      margin: 0 auto;
      padding-top: 5.0521vw;
      position: relative;
      .artist-carousel {
        width: 72.9167vw;
        overflow: hidden;
        position: relative;
        &:hover {
          .artist-car-item,
          .scroll-list1 {
            animation-play-state: paused;
          }
        }
        .artist-car-item {
          min-width: 100%;
          animation: scroll 30s linear infinite;
          display: flex;
          &.no-scroll {
            width: auto !important;
            animation: none;
          }
          &.flexend {
            justify-content: flex-end;
          }
          .artist-card-item {
            width: 13.75vw;
            height: 19.2708vw;
            padding: 0.5208vw;
            background: url("~@/assets/img/home/artist-item-bg.png");
            box-sizing: border-box;
            margin-right: 1.0417vw;
            background-size: 100% 100%;
            text-align: center;
            cursor: pointer;
            overflow: hidden;
            &:last-child {
              margin-right: 0;
            }
            .img {
              width: 12.7083vw;
              height: 14.1667vw;
              border-bottom-left-radius: 50% 0;
              border-bottom-right-radius: 50% 0;
              position: relative;
              overflow: visible;
              object-fit: cover;
              // clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);

              &::before {
                left: 50%;
                bottom: -0.7813vw;
                width: 26.0417vw;
                height: 26.0417vw;
                transform: translateX(-50%);
                content: "";
                position: absolute; // 关键***
                background-color: transparent;
                background-image: radial-gradient(
                  circle at 50% 50%,
                  transparent 66%,
                  #f2e6d5 66%,
                  #f2e6d5 100%
                );
              }
            }
            .title {
              font-size: 1.0417vw;
              font-family: AppleSystemUIFont;
              color: #000000;
              line-height: 1.0417vw;
              padding-top: 0.8333vw;
              font-weight: bold;
              box-sizing: border-box;
            }
            .desc {
              font-size: 0.7292vw;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #555555;
              line-height: 0.7292vw;
              padding-top: 0.7292vw;
              box-sizing: border-box;
            }
          }
        }
        .scroll-list1 {
          /* 滚动动画 */
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          animation: scroll1 30s linear infinite;
        }
      }
      .arrow {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -2.2396vw;
        width: 100%;
        .left {
          cursor: pointer;
          width: 4.1667vw;
          height: 2.2396vw;
        }
        .right {
          cursor: pointer;
          width: 4.1667vw;
          height: 2.2396vw;
        }
      }
    }
  }
}
</style>
