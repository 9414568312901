<template>
  <div class="artist-page">
    <TitleItemVue title="人艺人">
      <template slot="operation">
        <div class="header-right-btn" @click="toMore()">
          <div class="header-right-btn-title">查看更多</div>
          <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
          />
        </div>
      </template>
      <template slot="content">
        <div class="content-detail">
          <div class="content-header">
            <!-- 时间轴 -->
            <div class="line-box">
              <div
                :class="`line-item ${activeTab === item.value ? 'active' : ''}`"
                v-for="item in tabList"
                :key="item.value"
                @click="changeTab(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <swiper
            :options="swiperOptionTop"
            class="artist-carousel"
            ref="swiperTop"
            :style="{
              height:
                dataList[activeTab] && dataList[activeTab][0]?.length > 2
                  ? '13.01rem'
                  : '6.5067rem',
            }"
          >
            <swiper-slide
              v-for="(list, index) in dataList[activeTab]"
              :key="index"
              class="artist-car-item"
            >
              <div
                v-for="(item, i) in list"
                :key="item.id"
                class="artist-card-item"
                @click="toDetail(item)"
              >
                <div v-if="list.length % 4 != 0 && i == list.length - 1">
                  <c-image class="img" :src="item.image" />
                  <div class="title">{{ item.name }}</div>
                  <div class="desc">{{ activeName }}</div>
                  <!-- <template v-if="list.length % 4 == 3">
                    <div
                      style="
                        position: absolute;
                        right: 0.1333rem;
                        bottom: 0.2667rem;
                      "
                    >
                      <div class="header-right-btn" @click="toMore2()">
                        <div class="header-right-btn-title">查看更多</div>
                        <img
                          class="header-right-btn-img"
                          src="@/assets/img/home/to-more-icon.png"
                        />
                      </div>
                    </div>
                  </template> -->
                  <template v-if="list.length % 4 == 2 || list.length % 4 == 3">
                    <div
                      style="position: absolute; right: 0.1333rem; top: 6.5rem"
                    >
                      <div class="header-right-btn" @click="toMore2()">
                        <div class="header-right-btn-title">查看更多</div>
                        <img
                          class="header-right-btn-img"
                          src="@/assets/img/home/to-more-icon.png"
                        />
                      </div>
                    </div>
                  </template>
                  <template v-if="list.length % 4 == 1">
                    <div style="position: absolute; right: 0.1333rem; top: 0">
                      <div class="header-right-btn" @click="toMore2()">
                        <div class="header-right-btn-title">查看更多</div>
                        <img
                          class="header-right-btn-img"
                          src="@/assets/img/home/to-more-icon.png"
                        />
                      </div>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <c-image class="img" :src="item.image" />
                  <div class="title">{{ item.name }}</div>
                  <div class="desc">{{ activeName }}</div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import TitleItemVue from "./TitleItem.vue";
import { clickEntertainer } from "@/service/PeopleEntertainers";
export default {
  components: {
    TitleItemVue,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      activeTab: "founder",
      tabList: [
        {
          title: "创始人",
          value: "founder",
        },
        // {
        //   title: "编剧",
        //   value: "screenwriter",
        // },
        // {
        //   title: "导演",
        //   value: "director",
        // },
        // {
        //   title: "设计",
        //   value: "design",
        // },
        {
          title: "演员",
          value: "actor",
        },
        // {
        //   title: "舞美",
        //   value: "choreographer",
        // },
      ],
      activeName: "创始人",
      swiperIndex: 0,
      swiperOptionTop: {
        spaceBetween: 0,
        autoplay: {
          //自动轮播
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
    };
  },
  props: {
    list: {
      type: Object,
      default() {},
    },
    backgroundPosition: String,
  },
  computed: {
    dataList() {
      let obj = {};
      Object.keys(this.list).forEach((key) => {
        obj[key] = this.groupJsonArray(this.list[key], 4);
      });
      return obj;
    },
    swiperobj1() {
      if (!this.isMounted) return { index: 0 };
      return this.$refs.swiperTop.swiper;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    groupJsonArray(jsonArray, size) {
      // 解析 JSON 字符串为 JavaScript 数组
      const arr = jsonArray;

      return arr.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }

        acc[chunkIndex].push(curr);

        return acc;
      }, []);
    },
    changeTab(item) {
      this.swiperobj1.slideTo(0, 100);
      this.activeTab = item.value;
      this.activeName = item.title;
    },
    changeCarousel(e) {
      this.swiperIndex = e;
    },
    changeSwiper(index) {
      if (index < 0) {
        index = this.dataList.length - 1;
      }
      if (index > this.dataList.length - 1) {
        index = 0;
      }
      this.$refs.carousel.setActiveItem(index);
    },
    toDetail(item) {
      clickEntertainer(item.targetId);
      window.open(`#/PeopleEntertainers/detail?id=${item.targetId}`, "_self");
    },
    toMore() {
      window.open(`#/PeopleEntertainers/founder`, "_self");
    },
    toMore2() {
      window.open(`#/PeopleEntertainers/${this.activeTab}`, "_self");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.artist-page {
  width: 100%;
  // height: 100%;
  // background: url("~@/assets/img/home/artist-bg.png") no-repeat;
  // background-size: cover;
  // padding: 0 30/75rem;
  box-sizing: border-box;
  ::v-deep(.header) {
    padding: 50/75rem 30/75rem 0;
    box-sizing: border-box;
  }
  .header-right-btn {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4/75rem;
    padding: 9/75rem 16/75rem;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 22/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #212122;
      line-height: 33/75rem;
    }
    .header-right-btn-img {
      margin-left: 12/75rem;
      width: 16/75rem;
      height: 10/75rem;
    }
  }

  .content {
    .content-title-bg {
      position: absolute;
      writing-mode: vertical-lr;
      left: 70/75rem;
      top: 80/75rem;
      text-align: left;
      .title {
        font-size: 100/75rem;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
        font-weight: 800;
        color: rgba(191, 128, 95, 0.5);
        line-height: 100/75rem;
        opacity: 0.3;
        white-space: nowrap;
      }
      .tips {
        font-size: 12/75rem;
        font-family: AppleSystemUIFont;
        color: #707070;
        line-height: 12/75rem;
        margin-left: -26/75rem;
        margin-top: 8/75rem;
        white-space: nowrap;
      }
    }
    .content-detail {
      margin: 0 15/75rem;
      position: relative;
      .content-header {
        position: relative;
        padding: 0 15/75rem;
        box-sizing: border-box;
        .line-box {
          width: 100%;
          display: flex;
          // justify-content: space-between;
          border-bottom: 1/75rem solid #e8e7e7;
          &::-webkit-scrollbar {
            display: none;
          }
          .line-item {
            font-size: 30/75rem;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #212122;
            line-height: 45/75rem;
            padding-bottom: 20/75rem;
            white-space: nowrap;
            & + .line-item {
              margin-left: 60/75rem;
            }
            &.active {
              font-weight: 800;
              color: #f9680d;
              border-bottom: 3/75rem solid #f9680d;
            }
          }
        }
      }
      .artist-carousel {
        position: relative;
        margin-top: 30/75rem;
        ::v-deep(.swiper-pagination) {
          bottom: 0;
          .swiper-pagination-bullet {
            width: 12/75rem;
            height: 12/75rem;
            border-radius: 6/75rem;
            background: #e8e7e7;
            opacity: 1;
            &.swiper-pagination-bullet-active {
              width: 26/75rem;
              background-color: #f9680d !important;
              opacity: 1;
            }
          }
        }
        .artist-car-item {
          padding: 0 15/75rem;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .artist-card-item {
            width: 336/75rem;
            height: 470/75rem;
            padding: 20/75rem 18/75rem;
            background: url("~@/assets/img/home/artist-item-bg.png");
            box-sizing: border-box;
            margin-bottom: 18/75rem;
            background-size: 100% 100%;
            text-align: center;
            overflow: hidden;
            cursor: pointer;
            &:nth-of-type(2n) {
              margin-right: 0;
            }
            .img {
              width: 100%;
              height: 330/75rem;
              position: relative;
              overflow: visible;
              object-fit: cover;

              &::before {
                left: 50%;
                bottom: -15px;
                width: 500/75rem;
                height: 500/75rem;
                transform: translateX(-50%);
                content: "";
                position: absolute; // 关键***
                background-color: transparent;
                background-image: radial-gradient(
                  circle at 50% 50%,
                  transparent 66%,
                  #f2e6d5 66%,
                  #f2e6d5 100%
                );
              }
            }
            .title {
              font-size: 30/75rem;
              font-family: AppleSystemUIFont;
              color: #212122;
              line-height: 45/75rem;
              padding-top: 20/75rem;
              font-weight: bold;
              box-sizing: border-box;
            }
            .desc {
              font-size: 24/75rem;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #909399;
              line-height: 30/75rem;
              padding-top: 10/75rem;
              box-sizing: border-box;
            }
          }
        }
      }
      .arrow {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -35/75rem;
        width: 100%;
        .left {
          cursor: pointer;
          width: 80/75rem;
          height: 43/75rem;
        }
        .right {
          cursor: pointer;
          width: 80/75rem;
          height: 43/75rem;
        }
      }
    }
  }
}
</style>
