<template>
  <div class="arts-lecture-page" :class="theme == 'holiday' ? 'system-renyishijie' : ''" :style="{ backgroundPosition }">
    <TitleItemVue title="人艺视界">
      <template slot="operation"
        ><div class="header-right-btn" @click="toMore()">
          <div class="header-right-btn-title" :class="theme == 'holiday' ? 'system-color-text-y' : ''">查看更多</div>
          <template>
            <span v-if="theme == 'holiday'" class="system-color-text-y" style="font-size: 1.0417vw;margin-left: 0.7813vw;">→</span>
            <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
            v-else
          />
          </template>
        </div>
      </template>
      <template slot="content">
        <div class="content-title-bg">
          <div class="title">ART VIDEO</div>
          <div class="tips">ART VIDEO —————— 03</div>
        </div>
        <div class="content-detail">
          <div class="content-left">
            <div
              class="content-left-sub"
              v-for="(item, index) in listLeft"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="left-img">
                <c-image :src="item.image" class="img" />
              </div>
              <div class="right-box">
                <div class="title" :class="theme == 'holiday' ? 'system-color-text-white' : ''">{{ item.name }}</div>
                <div class="time" :class="theme == 'holiday' ? 'system-color-text-white2' : ''">
                  发布时间：{{
                    item.targetCreateTime &&
                    dayjs(item.targetCreateTime).format("YYYY-MM-DD")
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="content-right" @click="toDetail(rightItem)">
            <c-image :src="rightItem.image" class="video" />
            <c-image
              class="play"
              :src="require('@/assets/img/home/play.png')"
            />
            <div class="desc">
              {{ rightItem.name }}
            </div>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import dayjs from "dayjs";
import TitleItemVue from "./TitleItem.vue";
export default {
  components: {
    TitleItemVue,
  },
  data() {
    return {
      dayjs,
      // list: [
      //   {
      //     img: "",
      //     title: "当艺术家走出戏外，当哲学家走进剧场",
      //     time: "2020-07-02",
      //   },
      //   {
      //     img: "",
      //     title: "当艺术家走出戏外，当哲学家走进剧场",
      //     time: "2020-07-02",
      //   },
      //   {
      //     img: "",
      //     title: "当艺术家走出戏外，当哲学家走进剧场",
      //     time: "2020-07-02",
      //   },
      // ],
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    listRight: {
      type: Array,
      default: () => [],
    },
    backgroundPosition: String,
  },
  computed: {
    listLeft() {
      return this.list;
    },
    rightItem() {
      return this.listRight[0] || {};
    },
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  methods: {
    toMore() {
      window.open(`#/culture/video/index`, "_blank");
    },
    toDetail(item) {
      window.open(`#/culture/video/detail/${item.targetId}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.arts-lecture-page {
  width: 100%;
  background-image: url("~@/assets/img/home/arts-bg.png");
  background-size: cover;
  box-sizing: border-box;
  padding-bottom: 5.7813vw;

  .header-right-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 1.0417vw;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #f8d39f;
      line-height: 1.0417vw;
    }
    .header-right-btn-img {
      margin-left: 0.625vw;
      width: 0.8333vw;
      height: 0.5208vw;
    }
  }
  .content {
    .content-title-bg {
      .title {
        color: rgba(191, 128, 95, 0.5);
      }
      .tips {
        color: #f8d39f;
      }
    }
    .content-detail {
      width: 72.9167vw;
      margin: 0 auto;
      display: flex;
      text-align: left;
      .content-left {
        padding-top: 3.8021vw;
        .content-left-sub {
          width: 29.3229vw;
          height: 7.5521vw;
          display: flex;
          cursor: pointer;
          & + .content-left-sub {
            margin-top: 1.6667vw;
          }
          .left-img {
            flex-shrink: 0;
            width: 11.4583vw;
            height: 7.5521vw;
            border-radius: 0.4167vw;
            margin-right: 1.6146vw;
            overflow: hidden;
            position: relative;
            .img {
              width: 11.4583vw;
              height: 7.5521vw;
              border-radius: 0.4167vw;
            }

            &::before {
              content: "";
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              z-index: 2;
              transition: all 0.3s ease-in-out;
            }
            &::after {
              content: "";
              opacity: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 1.5625vw;
              height: 1.5625vw;
              transform: translateX(-50%) translateY(-50%);
              .background-image("/home/play.png");
              background-size: cover;
              z-index: 3;
              transition: all 0.3s ease-in-out;
            }
            &:hover {
              &::before,
              &::after {
                opacity: 1;
              }
            }
          }
          .right-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0.5208vw 0;
            .title {
              font-size: 1.25vw;
              font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: 800;
              color: #e8e8e8;
              line-height: 1.7708vw;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 这里是超出几行省略 */
              overflow: hidden;
            }
            .time {
              font-size: 0.7292vw;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #b0b0b0;
            }
          }
        }
      }
      .content-right {
        position: relative;
        margin: 3.8021vw 0 0 1.9271vw;
        width: 41.6667vw;
        height: 26.0417vw;
        border-radius: 0.4167vw;
        overflow: hidden;
        cursor: pointer;
        .video {
          width: 41.6667vw;
          height: 26.0417vw;
          border-radius: 0.4167vw;
        }
        .play {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 3.3333vw;
          height: 3.3333vw;
          transition: all 0.3s ease-in-out;
          z-index: 3;
          opacity: 0;
        }

        &::before {
          content: "";
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 2;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          &::before,
          .play {
            opacity: 1;
          }
        }
        .desc {
          position: absolute;
          width: 22.9167vw;
          top: 4.1146vw;
          left: 1.6667vw;
          font-size: 1.875vw;
          font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
          font-weight: 800;
          color: #ffffff;
          line-height: 2.3958vw;
        }
      }
    }
  }
}
</style>
