<template>
  <div class="category-title">
    <div class="header">
      <div class="title">
        <img
          v-if="title === '人艺资讯'"
          src="@/assets/img/home/new-center-title.png"
          class="img"
        />
        <img
          v-else-if="title === '人艺演出'"
          src="@/assets/img/home/performance-title.png"
          class="img"
        />
        <img
          v-else-if="title === '剧目典藏'"
          src="@/assets/img/home/repertoire-title.png"
          class="img"
        />
        <img
          v-else-if="title === '人艺视界'"
          src="@/assets/img/home/arts-title.png"
          class="img"
        /><img
          v-else-if="title === '人艺人'"
          src="@/assets/img/home/artist-title.png"
          class="img small"
        />
        <!-- <div class="title-en">{{ entitle }}</div>
        <div class="title-ch">{{ title }}</div> -->
      </div>
      <div class="operation">
        <slot name="operation"></slot>
      </div>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    entitle: {
      type: String,
      default: "",
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.category-title {
  position: relative;
  > .header {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin: 0 auto 50/75rem;
    padding-top: 50/75rem;
    box-sizing: content-box;
    // padding: 70/75rem 260/75rem 90/75rem 260/75rem;
    .img {
      width: 265/75rem;
      height: 66/75rem;
    }
    .small {
      width: 222/75rem;
    }
    .operation {
    }
  }
  .content {
    width: 100%;
    margin: auto;
  }
}
</style>
