import { render, staticRenderFns } from "./TitleItem.vue?vue&type=template&id=462c2d94&scoped=true"
import script from "./TitleItem.vue?vue&type=script&lang=js"
export * from "./TitleItem.vue?vue&type=script&lang=js"
import style0 from "./TitleItem.vue?vue&type=style&index=0&id=462c2d94&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462c2d94",
  null
  
)

export default component.exports