import service from "./index";

//获取顶部轮播图
export const getTopBanner = (data) => {
  return service({
    url: "/open/portal/article/page",
    method: "post",
    data,
  });
};

//获取博物馆简介
export const getMuseumDetail = (data) => {
  return service({
    url: "/open/portal/article/getByLevel",
    method: "post",
    data,
  });
};

//获取陈列展品列表
export const getExhibitList = (data = {}) => {
  return service({
    url: `/open/portal/exhibitionDisplay/page`,
    method: "post",
    data,
  });
};

//获取陈列展品详情
export const getExhibitDetail = (id) => {
  return service({
    url: `/open/portal/exhibitionDisplay/getById/${id}`,
    method: "get",
    id,
  });
};

//点击量+1陈列展品
export const chickExihibit = (id) => {
  return service({
    url: `/open/portal/exhibitionDisplay/hit/${id}`,
    method: "get",
    id,
  });
};

//获取数字展览列表
export const getDigitalList = (data = {}) => {
  return service({
    url: `/open/portal/exhibition/page`,
    method: "post",
    data,
  });
};

//获取陈列展品详情
export const getDigitalDetail = (id) => {
  return service({
    url: `/open/portal/exhibition/getById/${id}`,
    method: "get",
    id,
  });
};

//点击量+1陈列展品
export const chickDigital = (id) => {
  return service({
    url: `/open/portal/exhibition/hit/${id}`,
    method: "get",
    id,
  });
};
