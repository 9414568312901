<template>
  <div class="header">
    <img class="header-logo" :src="bgSrc" alt="" @click="goHome" />
    <Menu :isWhite="isWhite" />
  </div>
</template>

<script>
import bg from "@/assets/img/header-logo.png";
import bgWhite from "@/assets/img/header-logo-white.png";
import Menu from "./Menu.vue";
export default {
  components: {
    Menu,
  },
  props: {
    isWhite: Boolean,
  },
  computed: {
    bgSrc() {
      return this.isWhite ? bgWhite : bg;
    },
  },
  methods: {
    goHome() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &-logo {
    width: 17.3438vw;
    height: 2.0833vw;
    margin-right: 2.6042vw;
    cursor: pointer;
  }
}
</style>
