<template>
  <vue-danmaku
    ref="danmaku"
    :danmus="danmus"
    use-slot
    loop
    :speeds="30"
    :channels="1"
    :top="10"
    style="position: initial"
  >
    <!-- 弹幕插槽（vue 2.6.0 及以上版本可使用 v-slot:dm="{ index, danmu }"语法） -->
    <template slot="dm" slot-scope="{ index, danmu }">
      <div class="dm-item" @click="toDetail(danmu)">
        <div class="dm-tips" :class="theme == 'holiday' ? 'system-color-bg' : ''">NEWS</div>
        <div class="dm-txt">{{ danmu.title }}</div>
      </div>
    </template>
    <!-- 容器插槽 -->
    <div></div>
  </vue-danmaku>
</template>

<script>
import vueDanmaku from "vue-danmaku";

export default {
  components: {
    vueDanmaku,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    page: Number,
  },
  computed: {
    danmus() {
      return this.list;
    },
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  watch: {
    page() {
      if (this.page === 1) {
        this.$refs.danmaku?.play();
        this.$refs.danmaku?.show();
      } else {
        this.$refs.danmaku?.pause();
        this.$refs.danmaku?.hide();
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail(item) {
      if (item.sourceUrl) {
        window.open(item.sourceUrl, "_self");
      } else if (item.surveyId) {
        // 问卷调查
        window.open(`#/survey/index/${item.surveyId}`, "_self");
      } else {
        // 文章
        window.open(`#/ArtsInformation/detail?id=${item.id}`, "_self");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.dm-item {
  display: flex;
  align-items: center;
  padding: 5/75rem;
  border-radius: 20/75rem;
  box-sizing: border-box;
  height: 56/75rem;
  box-sizing: border-box;
  cursor: pointer;
  .dm-tips {
    font-size: 16/75rem;
    background: #f9680d;
    border-radius: 20/75rem;
    padding: 7/75rem 12/75rem;
    box-sizing: border-box;
  }
  .dm-txt {
    display: inline-block;
    padding-right: 44/75rem;
    padding-left: 22/75rem;
    font-size: 18/75rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #fff;
    line-height: 27/75rem;
    box-sizing: border-box;
  }
}
</style>
