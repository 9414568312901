var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"performance-page",style:({ backgroundPosition: _vm.backgroundPosition })},[_c('TitleItemVue',{attrs:{"title":"人艺演出"}},[_c('template',{slot:"operation"},[_c('div',{staticClass:"operation",on:{"click":_vm.changeMonth}},[_vm._v(" "+_vm._s(_vm.monthValueTemp)+" ")])]),_c('template',{slot:"content"},[_c('div',{staticClass:"content-title-bg"},[_c('div',{staticClass:"title"},[_vm._v("PERFORMANCE")]),_c('div',{staticClass:"tips"},[_vm._v("ART PERFORMANCE —————— 02")])]),_c('div',{staticClass:"content-detail"},[_c('div',{staticClass:"content-header"},[_c('img',{staticClass:"swiper-left",attrs:{"src":require("@/assets/img/home/performance-left-icon.png")},on:{"click":function($event){return _vm.changePerformanceSwiper(_vm.dateSwiperIndex - 1)}}}),_c('img',{staticClass:"swiper-right",attrs:{"src":require("@/assets/img/home/performance-right-icon.png")},on:{"click":function($event){return _vm.changePerformanceSwiper(_vm.dateSwiperIndex + 1)}}}),_c('div',{staticClass:"performance-carousel"},[_c('div',[_c('div',{staticClass:"day-box"},_vm._l((_vm.days),function(ls){return _c('div',{key:ls.time,class:`${
                    _vm.dateActiveValue === ls.time ? 'active' : ''
                  } day-sub`,on:{"click":function($event){$event.stopPropagation();return _vm.changeDayValue(ls.time)}}},[(_vm.dateActiveValue === ls.time)?_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/home/swiper-active-bg.png")}}):_vm._e(),_c('div',{staticClass:"day-value"},[_vm._v(_vm._s(ls.day))]),_c('div',{staticClass:"day-dot"}),_c('div',{staticClass:"day-weekday"},[_vm._v(_vm._s(ls.weekday))])])}),0)])])]),_c('div',{staticClass:"content-swiper"},[(_vm.dataList.length > 0)?[_c('el-carousel',{ref:"carousel",staticClass:"artist-carousel",attrs:{"loop":false,"autoplay":false,"indicator-position":"outside","trigger":"click"},on:{"change":_vm.changeCarousel}},_vm._l((_vm.dataList),function(list,index){return _c('el-carousel-item',{key:index,staticClass:"artist-car-item"},_vm._l((list),function(item){return _c('div',{key:item.id,staticClass:"artist-card-item",on:{"click":function($event){$event.stopPropagation();return _vm.handleDetail(item)}}},[_c('div',{staticClass:"img-box"},[_c('c-image',{staticClass:"img",attrs:{"src":item.image}}),(item.performerName)?_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(item.performerName)+" ")]):_vm._e(),(
                        _vm.dateActiveValue
                          ? _vm.dayjs(_vm.dateActiveValue).valueOf() >= _vm.nowDate
                          : true
                      )?_c('div',{staticClass:"buy-btn",class:_vm.theme == 'holiday' ? 'system-color-bg' : '',on:{"click":function($event){return _vm.toBuy()}}},[_vm._v(" 购票 ")]):_vm._e()],1),_c('div',{staticClass:"bot"},[_c('div',{staticClass:"title overflow-ellipsis"},[_vm._v(" "+_vm._s(item.repertoireName)+" ")]),_c('div',{staticClass:"line"},[_c('c-image',{attrs:{"src":require('@/assets/img/home/performance-icon2.png')}}),_c('span',[_vm._v(" "+_vm._s(item.showTime))])],1),_c('div',{staticClass:"line"},[_c('c-image',{attrs:{"src":require('@/assets/img/home/performance-icon1.png')}}),_c('span',[_vm._v(" "+_vm._s(item.theatresName))])],1)])])}),0)}),1),_c('div',{staticClass:"arrow"},[_c('img',{staticClass:"left",attrs:{"src":require("@/assets/img/home/swiper-arrow-left.png")},on:{"click":function($event){return _vm.changeSwiper(_vm.swiperIndex - 1)}}}),_c('img',{staticClass:"right",attrs:{"src":require("@/assets/img/home/swiper-arrow-right.png")},on:{"click":function($event){return _vm.changeSwiper(_vm.swiperIndex + 1)}}})])]:[_c('div',{staticClass:"xiuxi"},[_vm._v("今日演出休息")])]],2)])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }