<template>
  <div
    class="arts-lecture-page"
    :style="{ backgroundPosition }"
    :class="theme == 'holiday' ? 'system-color-bg-color' : ''"
  >
    <TitleItemVue title="人艺视界">
      <template slot="operation"
        ><div class="header-right-btn" @click="toMore()">
          <div class="header-right-btn-title">查看更多</div>
          <template>
            <span v-if="theme == 'holiday'" class="system-color-text-y youarrow"
              >→</span
            >
            <img
              class="header-right-btn-img"
              src="@/assets/img/home/to-more-icon.png"
              v-else
            />
          </template>
        </div>
      </template>
      <template slot="content">
        <div class="content-detail">
          <div class="content-right" @click="toDetail(rightItem)">
            <c-image :src="rightItem.image" class="video" />
            <c-image
              class="play"
              :src="require('@/assets/img/home/play.png')"
            />
            <div class="desc">
              {{ rightItem.name }}
            </div>
          </div>
          <div class="content-left">
            <div
              class="content-left-sub"
              v-for="(item, index) in listLeft"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="left-img">
                <c-image :src="item.image" class="img" />
              </div>
              <div class="right-box">
                <div class="title" :class="theme == 'holiday' ? 'system-color-text-white' : ''">{{ item.name }}</div>
                <div class="time" :class="theme == 'holiday' ? 'system-color-text-white2' : ''">
                  发布时间：{{
                    item.targetCreateTime &&
                    dayjs(item.targetCreateTime).format("YYYY-MM-DD")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import dayjs from "dayjs";
import TitleItemVue from "./TitleItem.vue";
export default {
  components: {
    TitleItemVue,
  },
  data () {
    return {
      dayjs,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    listRight: {
      type: Array,
      default: () => [],
    },
    backgroundPosition: String,
  },
  computed: {
    listLeft () {
      return this.list;
    },
    rightItem () {
      return this.listRight[0] || {};
    },
    theme () {
      return this.$store.state.systemTheme;
    }
  },
  methods: {
    toMore () {
      window.open(`#/culture/video/index`, "_self");
    },
    toDetail (item) {
      window.open(`#/culture/video/detail/${item.targetId}`, "_self");
    },
  },
};
</script>

<style lang="less" scoped>
.youarrow {
  font-size: 22/75rem;
  margin-left: 15/75rem;
}
.arts-lecture-page {
  width: 100%;
  background-image: url("~@/assets/img/home/arts-bg.png");
  background-size: cover;
  padding: 0 30/75rem;
  box-sizing: border-box;

  .header-right-btn {
    display: flex;
    align-items: center;
    background: rgba(245, 245, 245, 0.12);
    border-radius: 4/75rem;
    padding: 9/75rem 16/75rem;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 22/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #f8d39f;
      line-height: 33/75rem;
    }
    .header-right-btn-img {
      margin-left: 12/75rem;
      width: 16/75rem;
      height: 10/75rem;
    }
  }
  .content {
    .content-detail {
      width: 100%;
      margin: 0 auto;
      text-align: left;
      padding-bottom: 50/75rem;
      .content-right {
        position: relative;
        width: 100%;
        height: 454/75rem;
        border-radius: 8/75rem;
        .video {
          width: 100%;
          height: 454/75rem;
          border-radius: 8/75rem;
        }
        .play {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 80/75rem;
          height: 80/75rem;
        }
        .desc {
          position: absolute;
          width: 100%;
          left: 0/75rem;
          bottom: 0/75rem;
          font-size: 26/75rem;
          font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
          font-weight: 800;
          color: #ffffff;
          line-height: 26/75rem;
          padding-left: 20/75rem;
          padding-top: 18/75rem;
          padding-bottom: 26/75rem;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.4) 100%
          );
        }
      }
      .content-left {
        padding-top: 20/75rem;
        .content-left-sub {
          width: 100%;
          height: 190/75rem;
          display: flex;
          cursor: pointer;
          & + .content-left-sub {
            margin-top: 30/75rem;
          }
          .left-img {
            width: 288/75rem;
            height: 190/75rem;
            border-radius: 8/75rem;
            margin-right: 32/75rem;
            .img {
              width: 100%;
              height: 100%;
              border-radius: 8/75rem;
            }
          }
          .right-box {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 5rem;
            .title {
              font-size: 26/75rem;
              font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: 800;
              color: #fff;
              line-height: 40/75rem;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 这里是超出几行省略 */
              overflow: hidden;
            }
            .time {
              font-size: 22/75rem;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #b0b0b0;
            }
          }
        }
      }
    }
  }
}
</style>
