<template>
  <div class="new-center-page">
    <TitleItemVue title="人艺资讯">
      <template slot="operation">
        <div class="header-right-btn" @click="toDetail()">
          <div class="header-right-btn-title">查看更多</div>
          <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
          />
        </div>
      </template>
      <template slot="content">
        <div class="content-detail">
          <div class="content-top">
            <swiper
              :options="swiperOptionTop"
              class="center-carousel"
              ref="swiperTop"
            >
              <swiper-slide v-for="item in newList.referralTop" :key="item.id">
                <c-image
                  :src="item.image"
                  style="width: 100%; height: 100%; cursor: pointer"
                  @click="handleDetail(item)"
                />
                <div class="swiper-tips">
                  <div class="swiper-desc">
                    {{ item.name }}
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="content-right">
            <div
              :class="`content-right-item${index} content-right-item`"
              v-for="(item, index) in newList.referralRight"
              :key="index"
              @click="handleDetail(item)"
            >
              <div class="content-right-top">
                <c-image
                  v-if="
                    item.level2 === '剧院公告' &&
                    (item.image.includes('jpg') || item.image.includes('png'))
                  "
                  :src="item.image"
                  class="img"
                />
                <div
                  class="jygg"
                  v-else-if="
                    item.level2 === '剧院公告' &&
                    !(item.image.includes('jpg') || item.image.includes('png'))
                  "
                >
                  {{ item.name }}
                </div>
                <c-image v-else :src="item.image" class="img" />
                <div class="txt">{{ item.name }}</div>
              </div>

              <div class="content-right-bottom">
                <div class="title-header">
                  <img
                    class="img"
                    :src="
                      item.level2 === '剧院动态'
                        ? require('@/assets/img/home/new-center-icon3.png')
                        : require('@/assets/img/home/new-center-icon2.png')
                    "
                  />
                  <div class="title">{{ item.level2 }}</div>
                </div>
                <div class="time">
                  {{ dayjs(item.targetCreateTime).format("YYYY-MM-DD") }}
                </div>
              </div>
            </div>
          </div>
          <div class="content-bottom">
            <div
              :class="`content-bottom-item${index} content-bottom-item`"
              v-for="(item, index) in newList.referralLeft"
              :key="index"
              @click="handleDetail(item)"
            >
              <div class="content-bottom-left">
                <div class="txt">{{ item.name }}</div>
                <div class="title-header">
                  <img
                    class="img"
                    src="@/assets/img/home/new-center-icon1.png"
                  />
                  <div class="title">{{ item.level2 }}</div>
                </div>
                <!-- <div class="time">
                  {{ dayjs(item.targetCreateTime).format("YYYY-MM-DD") }}
                </div> -->
              </div>
              <div class="content-bottom-right">
                <c-image :src="item.image" class="img" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import dayjs from "dayjs";
import TitleItemVue from "./TitleItem.vue";
export default {
  components: {
    TitleItemVue,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperIndex: 0,
      dayjs,
      swiperOptionTop: {
        spaceBetween: 0,
        autoplay: {
          //自动轮播
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
    };
  },
  props: {
    list: {
      type: Object,
      default() {},
    },
    backgroundPosition: String,
  },
  methods: {
    changeCarousel(e) {
      this.swiperIndex = e;
    },
    changeSwiper(index) {
      if (index < 0) {
        index = this.newList.referralTop.length - 1;
      }
      if (index > this.newList.referralTop.length - 1) {
        index = 0;
      }
      this.$refs.carousel.setActiveItem(index);
    },
    toDetail() {
      window.open(`#/ArtsInformation/dynamic`, "_self");
    },
    toBuy() {
      window.open("http://piao.bjry.com", "_self");
    },
    handleDetail(item) {
      // 文章
      item.targetId &&
        window.open(`#/ArtsInformation/detail?id=${item.targetId}`, "_self");
    },
  },
  computed: {
    newList() {
      const obj = this.list || {};
      obj.referralLeft = obj.referralLeft || [];
      obj.referralTop = obj.referralTop || [];
      obj.referralRight = obj.referralRight || [];
      return obj;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.new-center-page {
  width: 100%;
  padding: 0 30/75rem;
  box-sizing: border-box;
  .header-right-btn {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4/75rem;
    padding: 9/75rem 16/75rem;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 22/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #212122;
      line-height: 33/75rem;
    }
    .header-right-btn-img {
      margin-left: 12/75rem;
      width: 16/75rem;
      height: 10/75rem;
    }
  }
  .content-detail {
    padding-bottom: 50/75rem;
    .content-top {
      position: relative;
      width: 100%;
      height: 366/75rem;
      background: #d8d8d8;
      border-radius: 12/75rem;
      box-sizing: border-box;

      .swiper-tips {
        width: 100%;
        height: 90/75rem;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 0/75rem 0/75rem 8/75rem 8/75rem;
        padding: 21/75rem 30/75rem 0 30/75rem;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        z-index: 2;
        .swiper-desc {
          font-size: 24/75rem;
          font-family: AppleSystemUIFont;
          color: #ffffff;
          line-height: 24/75rem;
          text-align: left;
          .text-overflow(1);
        }
      }
    }

    .content-right {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .content-right-item {
        width: 336/75rem;
        height: 340/75rem;
        background: #f2e6d5;
        border-radius: 8/75rem;
        // margin-bottom: 20/75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 18/75rem;
        cursor: pointer;
        .jygg {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 178/75rem;
          border-radius: 8/75rem 8/75rem 0 0;
          background: url("../../../../assets/img/home/jygg.jpg") no-repeat;
          background-size: cover;
          font-size: 26/75rem;
          font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
          font-weight: 800;
          color: #fff;
          padding: 20/75rem;
          box-sizing: border-box;
        }
        .content-right-top {
          width: 100%;
          height: 178/75rem;
          border-radius: 8/75rem 8/75rem 0 0;
          .img {
            width: 100%;
            height: 178/75rem;
            border-radius: 8/75rem 8/75rem 0 0;
            cursor: pointer;
          }
          .txt {
            text-align: left;
            padding: 20/75rem;
            height: 80/75rem;
            width: 100%;
            font-size: 26/75rem;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #212121;
            line-height: 30/75rem;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 这里是超出几行省略 */
            overflow: hidden;
            box-sizing: border-box;
          }
        }
        .content-right-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0/75rem 20/75rem 12/75rem 20/75rem;
          box-sizing: border-box;
          .title-header {
            display: flex;
            align-items: center;
            .img {
              width: 22/75rem;
              height: 22/75rem;
              line-height: 33/75rem;
            }
            .title {
              font-size: 22/75rem;
              font-family: AppleSystemUIFont;
              color: #000000;
              line-height: 33/75rem;
              margin-left: 6/75rem;
            }
          }
          .time {
            font-size: 22/75rem;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #909399;
            line-height: 33/75rem;
          }
        }
      }
    }

    .content-bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .content-bottom-item {
        width: 336/75rem;
        height: 170/75rem;
        background: #f0f5de;
        border-radius: 8/75rem;
        display: flex;
        padding: 20/75rem;
        box-sizing: border-box;
        margin-top: 18/75rem;
        cursor: pointer;
        &.content-bottom-item0 {
          background: #f5dede;
        }
        &.content-bottom-item3 {
          background: #dee9f6;
        }
        .content-bottom-left {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-around;
          width: 170/75rem;
          font-size: 26/75rem;
          font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
          font-weight: 800;
          color: #000000;
          line-height: 30/75rem;
          text-align: left;
          .title-header {
            display: flex;
            align-items: center;
            .img {
              width: 24/75rem;
              height: 24/75rem;
            }
            .title {
              text-align: left;
              font-size: 22/75rem;
              font-family: AppleSystemUIFont;
              color: #000000;
              line-height: 22/75rem;
              padding-left: 8/75rem;
              font-weight: 400;
            }
          }
          .txt {
            font-size: 26/75rem;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #212122;
            line-height: 30/75rem;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 这里是超出几行省略 */
            overflow: hidden;
          }
          .time {
            font-size: 14/75rem;
            font-family: AppleSystemUIFont;
            color: #717171;
            line-height: 14/75rem;
            margin-top: 24/75rem;
          }
        }
        .content-bottom-right {
          width: 100/75rem;
          height: 130/75rem;
          border-radius: 4/75rem;
          margin-left: 20/75rem;
          .img {
            width: 100/75rem;
            height: 130/75rem;
            border-radius: 4/75rem;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.home-mobile .center-carousel {
  height: 366/75rem;
  border-radius: 8/75rem;
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 12/75rem;
      height: 12/75rem;
      border-radius: 6/75rem;
      background-color: #ffffff;
      opacity: 0.6;
      &.swiper-pagination-bullet-active {
        width: 26/75rem;
        background-color: #ffffff;
        opacity: 1;
      }
    }
  }
}
</style>
