<template>
  <div class="people-art">
    <div class="people-art-content">
      <div class="row">
        <div class="people-art-sub1" @click="toArtDetail(footerList.activity)">
          <c-image :src="footerList.activity.image" class="img" />
        </div>
        <div class="people-art-sub3" @click="toArtDetail(footerList.activity)">
          <div class="header-title">
            <div class="header-name">人艺活动</div>
            <div class="title">
              {{
                footerList.activity.targetCreateTime &&
                dayjs(footerList.activity.targetCreateTime).format("MM.DD")
              }}
            </div>
          </div>
          <div class="title-desc">
            {{ footerList.activity.targetTitle }}
          </div>
        </div>
        <div class="people-art-sub2">
          <div class="header-sub2" @click="toMusemu">戏剧博物馆</div>
          <div class="content">
            <div
              class="detail-sub"
              v-for="(item, index) in footerList.museums"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="sub-item">
                <c-image :src="item.image" class="img" />
                <div class="right-sub">
                  <div class="title">{{ item.targetTitle }}</div>
                  <div class="time">
                    {{ dayjs(item.targetCreateTime).format("YYYY-MM-DD") }}
                  </div>
                </div>
              </div>
              <!-- <div class="btn">详情</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="people-art-sub4">
          <div class="book-sub">
            <div class="header-sub4" @@click="toMore">悦读人艺</div>
            <div class="content">
              <c-image :src="footerList.read.image" class="img" />
              <div class="content-right">
                <div class="title">
                  <div class="title-sub1">
                    {{ footerList.read.name }}
                  </div>
                  <div class="title-sub2"></div>
                </div>
                <div class="more" @click="toMore">查看更多</div>
              </div>
            </div>
          </div>
          <div class="book-sub">
            <div class="header-sub4" @click="toDetailView">观剧指南</div>
            <div class="content">
              <c-image :src="footerList.dramaGuide.image" class="img" />
              <div class="content-right">
                <div class="title">
                  <div class="tips">
                    了解观剧礼仪认知 让这趟艺术之旅变得更加独特。
                  </div>
                </div>
                <div class="more" @click="toDetailView">了解</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { chickDigital } from "@/service/theatreMuseum";
export default {
  data() {
    return {
      dayjs,
    };
  },
  props: {
    list: {
      type: Object,
      default() {},
    },
    footerDataList: {
      type: Object,
      default() {},
    },
    backgroundPosition: String,
  },
  computed: {
    footerList() {
      let obj = this.footerDataList || {};
      obj.activity = (obj.activity || [])[0] || {};
      obj.read = (obj.read || [])[0] || {};
      obj.dramaGuide = obj.dramaGuide || {};
      obj.museums = (obj.museums || []).slice(0, 3);
      return this.footerDataList;
    },
  },
  mounted() {},
  methods: {
    toArtDetail(item) {
      window.open(`#/ArtsInformation/detail?id=${item.targetId}`, "_self");
    },
    toDetail(item) {
      chickDigital(item.targetId);
      window.open(`#/theatreMuseum/digitalDetail?id=${item.targetId}`, "_self");
    },
    // 悦读人艺
    toMore() {
      window.open(`#/culture/periodical/index`, "_self");
    },
    // 观剧指南
    toDetailView() {
      window.open(`#/culture/guide/index`, "_self");
    },
    toMusemu() {
      window.open(`#/theatreMuseum`, "_self");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");

.people-art {
  width: 100%;
  background: #fff;
  position: relative;
  .people-art-content {
    padding: 50/75rem 0 0;
    box-sizing: border-box;
    .people-art-sub1 {
      position: relative;
      height: 448/75rem;
      border-radius: 12/75rem 12/75rem 0/75rem 0/75rem;
      margin: 0 20/75rem;
      .img {
        width: 100%;
        height: 448/75rem;
        border-radius: 12/75rem 12/75rem 0/75rem 0/75rem;
      }
      .title {
        position: absolute;
        font-size: 30/75rem;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        line-height: 30/75rem;
        left: 100/75rem;
        bottom: 40/75rem;
      }
    }
    .people-art-sub3 {
      height: 240/75rem;
      background: #bf805f;
      text-align: left;
      padding: 30/75rem;
      box-sizing: border-box;
      margin: 0 20/75rem;
      .header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .header-name {
          display: inline-block;
          font-size: 30/75rem;
          font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
          font-weight: bold;
          color: #ffffff;
        }
        .title {
          white-space: nowrap;
          font-size: 30/75rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -30/75rem;
          left: 0;
          width: 30/75rem;
          height: 2/75rem;
          background: #ffffff;
        }
      }
      .title-desc {
        font-size: 34/75rem;
        font-weight: 800;
        font-family: AppleSystemUIFont;
        color: #ffffff;
        line-height: 44/75rem;
        padding-top: 50/75rem;
        .text-overflow(2);
      }
    }
    .people-art-sub2 {
      background: #fff;
      text-align: left;
      padding: 40/75rem 0 30/75rem 0/75rem;
      box-sizing: border-box;
      margin: 0 20/75rem;
      .header-sub2 {
        font-size: 30/75rem;
        font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
        font-weight: bold;
        color: #f9680d;
        line-height: 30/75rem;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: -20/75rem;
          left: 0;
          width: 30/75rem;
          height: 2/75rem;
          background: #bf805f;
        }
      }
      .content {
        width: 100%;
        padding-top: 50/75rem;
        box-sizing: border-box;
        .detail-sub {
          display: flex;
          margin-bottom: 20/75rem;
          cursor: pointer;
          .sub-item {
            width: 100%;
            display: flex;
            background: #f8f8fa;
            border-radius: 12/75rem;
            // border-right: 1/75rem solid #bf805f;
            .img {
              width: 280/75rem;
              height: 180/75rem;
              border-radius: 12/75rem 0/75rem 0/75rem 12/75rem;
            }
            .right-sub {
              flex: 1;
              margin: 0 20/75rem;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .title {
                height: 80/75rem;
                font-size: 26/75rem;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #212122;
                line-height: 40/75rem;
                .text-overflow(2);
              }
              .time {
                font-size: 20/75rem;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #909399;
                line-height: 30/75rem;
              }
            }
          }
          .btn {
            font-size: 14/75rem;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #b0b0b0;
            line-height: 14/75rem;
            margin-left: 40/75rem;
            cursor: pointer;
          }
        }
      }
    }
    .people-art-sub4 {
      width: 100%;
      margin-left: -15px;
      background: #dcbcac;
      display: flex;
      justify-content: space-between;
      padding: 50/75rem 30/75rem;
      box-sizing: border-box;
      .book-sub {
        .header-sub4 {
          text-align: left;
          display: inline-block;
          font-size: 30/75rem;
          font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
          font-weight: bold;
          color: #21424b;
          line-height: 30/75rem;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: -30/75rem;
            left: 0;
            width: 30/75rem;
            height: 2/75rem;
            background: #ffffff;
          }
        }
        .content {
          display: flex;
          padding-top: 60/75rem;
          .img {
            width: 154/75rem;
            height: 198/75rem;
            border-radius: 8/75rem;
            margin-right: 20/75rem;
            flex-shrink: 0;
          }
          .content-right {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .title {
              .title-sub1 {
                max-width: 102/75rem;
                font-size: 25/75rem;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #21424b;
                line-height: 45/75rem;
                margin-bottom: 16/75rem;
                .text-overflow(2);
              }
              .title-sub2 {
                font-size: 25/75rem;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #21424b;
                line-height: 30/75rem;
              }
              .tips {
                max-width: 200/75rem;
                font-size: 24/75rem;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #21424b;
                line-height: 34/75rem;
                text-align: left;
                .text-overflow(3);
              }
            }
            .more {
              width: 128/75rem;
              font-size: 20/75rem;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #21424b;
              line-height: 14/75rem;
              padding: 8/75rem 26/75rem;
              border-radius: 20/75rem;
              border: 1/75rem solid #707070;
              box-sizing: border-box;
              white-space: nowrap;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
