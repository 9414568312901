var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artist-page",style:({ backgroundPosition: _vm.backgroundPosition })},[_c('TitleItemVue',{attrs:{"title":"人艺人"}},[_c('template',{slot:"operation"},[_c('div',{staticClass:"operation"},_vm._l((_vm.tabList),function(item){return _c('div',{key:item.value,class:`operation-item ${
            _vm.activeTab === item.value ? 'active' : ''
          }`,on:{"click":function($event){return _vm.changeTab(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]),_c('template',{slot:"content"},[_c('div',{staticClass:"content-title-bg"},[_c('div',{staticClass:"title"},[_vm._v("ONE ARTIST")]),_c('div',{staticClass:"tips"},[_vm._v("ONE ARTIST —————— 05")])]),_c('div',{staticClass:"content-detail"},[_c('div',{staticClass:"artist-carousel"},[_c('div',{key:_vm.key,class:[
              'artist-car-item',
              {
                'no-scroll': _vm.allList.length <= 5,
                flexend: _vm.allList.length <= 4,
              },
            ],style:({
              width: _vm.allList.length * 1.4792 + 'rem',
              animationDuration: `${_vm.allList.length * 2}s`,
            })},_vm._l((_vm.allList),function(item){return _c('div',{key:item.id,staticClass:"artist-card-item",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('c-image',{staticClass:"img",attrs:{"src":item.image,"errorType":"artist"}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.activeName))])],1)}),0),(_vm.allList.length > 5)?_c('div',{key:_vm.key + 1,staticClass:"artist-car-item scroll-list1",style:({
              width: _vm.allList.length * 1.4792 + 'rem',
              animationDuration: `${_vm.allList.length * 2}s`,
            })},_vm._l((_vm.allList),function(item){return _c('div',{key:item.id,staticClass:"artist-card-item",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('c-image',{staticClass:"img",attrs:{"src":item.image}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.activeName))])],1)}),0):_vm._e()])])]),_c('template',{slot:"bottom"},[_c('div',{staticClass:"header-right-btn",on:{"click":function($event){return _vm.toMore()}}},[_c('div',{staticClass:"header-right-btn-title"},[_vm._v("查看更多")]),_c('img',{staticClass:"header-right-btn-img",attrs:{"src":require("@/assets/img/home/to-more-icon.png")}})])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }