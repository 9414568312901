<template>
  <div class="performance-page">
    <TitleItemVue title="人艺演出">
      <template slot="operation">
        <div class="header-right-btn" @click="toMore">
          <div class="header-right-btn-title">查看更多</div>
          <img
            class="header-right-btn-img"
            src="@/assets/img/home/to-more-icon.png"
          />
        </div>
      </template>
      <template slot="content">
        <div class="content-detail">
          <div
            v-for="item in list"
            :key="item.id"
            class="artist-card-item"
            @click.stop="handleDetail(item)"
          >
            <c-image class="img" :src="item.image" />
            <div class="type" v-if="item.performerName">
              {{ item.performerName }}
            </div>
            <div class="bot">
              <div class="title overflow-ellipsis">
                {{ item.repertoireName }}
              </div>
              <div class="time">
                {{ item.showTime }}
              </div>
              <div class="buy-btn" @click.stop="toBuy()">购票</div>
            </div>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import dayjs from "dayjs";
import TitleItemVue from "./TitleItem.vue";
export default {
  components: {
    TitleItemVue,
  },
  data() {
    return {
      initialIndex: dayjs().date() > 15 ? 1 : 0,
      dataList: [],
      dateSwiperIndex: dayjs().date() > 15 ? 1 : 0,
      swiperIndex: 0,
      monthValue: dayjs().startOf("month"),
      monthValueTemp: dayjs().startOf("month").format("YYYY年MM月"),
      everyDay: [],
      newEveryDay: [],
      dateActiveValue: "",
    };
  },
  props: {
    backgroundPosition: String,
    list: Array,
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleDetail(item) {
      window.open(`#/performance/detail/${item.id}`, "_self");
    },
    toBuy() {
      window.open("http://piao.bjry.com", "_self");
    },
    toMore() {
      window.open(`#/performance/index`, "_self");
    },
  },
};
</script>

<style lang="less" scoped>
.performance-page {
  width: 100%;
  height: 100%;
  padding: 0 30/75rem;
  // height: 900/75rem;
  background-image: url("~@/assets/img/home/performance-bg.jpg");
  background-size: cover;
  box-sizing: border-box;
  .header-right-btn {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4/75rem;
    padding: 9/75rem 16/75rem;
    cursor: pointer;
    .header-right-btn-title {
      font-size: 22/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #212122;
      line-height: 33/75rem;
    }
    .header-right-btn-img {
      margin-left: 12/75rem;
      width: 16/75rem;
      height: 10/75rem;
    }
  }
  .content-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 20/75rem;
    flex-wrap: wrap;

    .artist-card-item {
      width: 336/75rem;
      // height: 638/75rem;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 30/75rem;
      &:last-child {
        margin-right: 0;
      }
      .img {
        width: 336/75rem;
        height: 470/75rem;
        box-shadow: 0/75rem 3/75rem 10/75rem 0/75rem rgba(0, 0, 0, 0.2);
        border-radius: 12/75rem;
      }
      .type {
        position: absolute;
        top: 20/75rem;
        left: 6/75rem;
        background: #e3c297;
        border-radius: 2/75rem;
        padding: 8/75rem 17/75rem;
        box-sizing: border-box;
        font-size: 22/75rem;
      }
      .bot {
        text-align: left;

        .title {
          font-size: 30/75rem;
          font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
          font-weight: 800;
          color: #212122;
          line-height: 45/75rem;
          letter-spacing: 2/75rem;
          padding-top: 30/75rem;
          box-sizing: border-box;
        }
        .time {
          font-size: 24/75rem;
          font-family: AppleSystemUIFont;
          color: #909399;
          line-height: 36/75rem;
          // padding-top: 10/75rem;
          box-sizing: border-box;
        }
        .buy-btn {
          display: inline-block;
          margin-top: 24/75rem;
          background: #df1518;
          border-radius: 4/75rem;
          padding: 12/75rem 34/75rem;
          box-sizing: border-box;
          font-size: 24/75rem;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
          line-height: 36/75rem;
          letter-spacing: 2/75rem;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
