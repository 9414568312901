<template>
  <div class="home-mobile">
    <!-- banner -->
    <Banner
      :list="pageList"
      :homeDataAll="homeDataAll"
      v-if="pageList.length"
    />
    <!-- tab -->
    <TabIcon />
    <!-- 人艺资讯 -->
    <NewCenterVue :list="centerList" />
    <!-- 人艺演出 -->
    <Performance :list="repertoires" />
    <!-- 人艺视界 -->
    <ArtslectureVue
      :list="videoLeftList"
      :listRight="videoRightList"
      v-if="videoLeftList.length"
    />
    <!-- 剧目典藏 -->
    <RepertoireCollection />
    <!-- 人艺人 -->
    <Artist :list="entertainersList" />
    <!-- 戏剧博物馆 -->
    <Activity :footerDataList="footerDataList" />
    <PageFooter />
  </div>
</template>

<script>
import { getHomePortal } from "@/service/home";
import Banner from "./components/Banner.vue";
import TabIcon from "./components/TabIcon.vue";
import NewCenterVue from "./components/NewCenter.vue";
import Performance from "./components/Performance.vue";
import ArtslectureVue from "./components/Artslecture.vue";
import RepertoireCollection from "./components/RepertoireCollection.vue";
import Artist from "./components/Artist.vue";
import Activity from "./components/Activity.vue";
// import bg from "@/assets/img/home/bg3.jpg";
// import springBg from "@/assets/img/spring/spring-top.png"
export default {
  components: {
    Banner,
    TabIcon,
    NewCenterVue,
    Performance,
    ArtslectureVue,
    RepertoireCollection,
    Artist,
    Activity,
  },
  data() {
    return {
      homeDataAll: {},
      pageList: []
    };
  },
  created() {
    this.pageList = [{ id: "bg", image: this.systemThemeInfo.thumbnail }]
  },
  methods: {
    async getHomeData() {
      try {
        const res = await getHomePortal();
        this.homeDataAll = res.data || {};
        let list =
          res.data.topBanners?.filter((it) => {
            return it.image;
          }) || [];
        // 最多显示7+1
        this.pageList = [{ id: "bg", imageWorld: this.systemThemeInfo.thumbnail }, ...list].slice(0, 8);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    centerList() {
      return {
        referralLeft: this.homeDataAll?.referralLeft || [],
        referralRight: this.homeDataAll?.referralRight || [],
        referralTop: this.homeDataAll?.referralTop || [],
      };
    },
    repertoires() {
      return this.homeDataAll?.repertoires || [];
    },
    videoLeftList() {
      return this.homeDataAll?.videoLeft || [];
    },
    videoRightList() {
      return this.homeDataAll?.videoRight || [];
    },
    entertainersList() {
      return this.homeDataAll?.entertainers || {};
    },
    footerDataList() {
      return {
        activity: this.homeDataAll?.activity || [],
        dramaGuide: this.homeDataAll?.dramaGuide || {},
        museums: this.homeDataAll?.museums || [],
        read: this.homeDataAll?.read || [],
      };
    },
    systemThemeInfo() {
      return this.$store.state.systemThemeInfo;
    },
  },
  mounted() {
    this.getHomeData();
  },
};
</script>

<style lang="less">
.home-mobile .artist-carousel {
  .el-carousel__arrow {
    display: none !important;
  }
  .el-carousel__indicators {
    .el-carousel__indicator--horizontal {
      padding: 10/75rem 5/75rem;
      .el-carousel__button {
        width: 12/75rem;
        height: 12/75rem;
        border-radius: 6/75rem;
      }
      &.is-active {
        .el-carousel__button {
          width: 26/75rem;
          background-color: #f9680d !important;
        }
      }
    }
  }
}
</style>
