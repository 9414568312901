<template>
  <div class="mobile-container">
    <div class="container-header" ref="header">
      <Header />
    </div>
    <div class="container-main" ref="main">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
export default {
  name: "Home",
  components: {
    Header,
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  .container-header {
    position: sticky;
    z-index: 1001;
    top: 0;
    margin: 0 auto;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>
