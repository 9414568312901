<template>
  <div class="performance-page" :style="{ backgroundPosition }">
    <TitleItemVue title="人艺演出">
      <template slot="operation">
        <div class="operation" @click="changeMonth">
          {{ monthValueTemp }}
          <!-- <div style="visibility: hidden; width: 1px; height: 0px">
            <el-date-picker
              ref="datePicker"
              v-model="monthValue"
              type="month"
              @change="monthChange"
              placeholder="选择月"
            />
          </div> -->
        </div>
      </template>
      <template slot="content">
        <div class="content-title-bg">
          <div class="title">PERFORMANCE</div>
          <div class="tips">ART PERFORMANCE —————— 02</div>
        </div>
        <div class="content-detail">
          <div class="content-header">
            <img
              @click="changePerformanceSwiper(dateSwiperIndex - 1)"
              class="swiper-left"
              src="@/assets/img/home/performance-left-icon.png"
            />
            <img
              @click="changePerformanceSwiper(dateSwiperIndex + 1)"
              class="swiper-right"
              src="@/assets/img/home/performance-right-icon.png"
            />
            <div class="performance-carousel">
              <div>
                <div class="day-box">
                  <div
                    :class="`${
                      dateActiveValue === ls.time ? 'active' : ''
                    } day-sub`"
                    v-for="ls in days"
                    :key="ls.time"
                    @click.stop="changeDayValue(ls.time)"
                  >
                    <img
                      v-if="dateActiveValue === ls.time"
                      src="@/assets/img/home/swiper-active-bg.png"
                      class="img"
                    />
                    <div class="day-value">{{ ls.day }}</div>
                    <div class="day-dot"></div>
                    <div class="day-weekday">{{ ls.weekday }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-swiper">
            <template v-if="dataList.length > 0">
              <el-carousel
                ref="carousel"
                :loop="false"
                :autoplay="false"
                indicator-position="outside"
                class="artist-carousel"
                trigger="click"
                @change="changeCarousel"
              >
                <el-carousel-item
                  class="artist-car-item"
                  v-for="(list, index) in dataList"
                  :key="index"
                >
                  <div
                    v-for="item in list"
                    :key="item.id"
                    class="artist-card-item"
                    @click.stop="handleDetail(item)"
                  >
                    <div class="img-box">
                      <c-image class="img" :src="item.image" />
                      <div class="type" v-if="item.performerName">
                        {{ item.performerName }}
                      </div>
                      <div
                        v-if="
                          dateActiveValue
                            ? dayjs(dateActiveValue).valueOf() >= nowDate
                            : true
                        "
                        class="buy-btn"
                        :class="theme == 'holiday' ? 'system-color-bg' : ''"
                        @click="toBuy()"
                      >
                        购票
                      </div>
                    </div>
                    <div class="bot">
                      <div class="title overflow-ellipsis">
                        {{ item.repertoireName }}
                      </div>
                      <div class="line">
                        <c-image
                          :src="
                            require('@/assets/img/home/performance-icon2.png')
                          "
                        />
                        <span> {{ item.showTime }}</span>
                      </div>
                      <div class="line">
                        <c-image
                          :src="
                            require('@/assets/img/home/performance-icon1.png')
                          "
                        />
                        <span> {{ item.theatresName }}</span>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <div class="arrow">
                <img
                  class="left"
                  @click="changeSwiper(swiperIndex - 1)"
                  src="@/assets/img/home/swiper-arrow-left.png"
                />
                <img
                  class="right"
                  @click="changeSwiper(swiperIndex + 1)"
                  src="@/assets/img/home/swiper-arrow-right.png"
                />
              </div>
            </template>
            <template v-else>
              <div class="xiuxi">今日演出休息</div>
            </template>
          </div>
        </div>
      </template>
    </TitleItemVue>
  </div>
</template>

<script>
import dayjs from "dayjs";
import TitleItemVue from "./TitleItem.vue";
import { getRepertoirePage } from "@/service/performance";
export default {
  components: {
    TitleItemVue,
  },
  data() {
    return {
      dayjs,
      initialIndex: dayjs().date() > 15 ? 1 : 0,
      dataList: [],
      dateSwiperIndex: dayjs().date() > 15 ? 1 : 0,
      swiperIndex: 0,
      monthValue: dayjs().startOf("month"),
      monthValueTemp: dayjs().startOf("month").format("YYYY年MM月"),
      everyDay: [],
      newEveryDay: [],
      dateActiveValue: "",
      nowDate: dayjs().startOf("day").valueOf(),
    };
  },
  props: {
    backgroundPosition: String,
    list: Array,
  },
  computed: {
    days() {
      return this.newEveryDay[this.dateSwiperIndex] || [];
    },
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  watch: {
    monthValue() {
      this.monthValueTemp = dayjs(this.monthValue)
        .startOf("day")
        .format("YYYY年MM月");
    },
  },
  mounted() {
    let date = new Date(),
      month = date.getMonth();
    this.newEveryDay = this.getEveryDay(date, month);
    setTimeout(() => {
      this.dataList = this.groupJsonArray(this.list || [], 5);
    }, 1000);
    // this.getList();
  },
  methods: {
    changeMonth() {
      // this.$refs.datePicker.pickerVisible = true;
    },
    monthChange(date) {
      this.getEveryDay(date, date.getMonth());
    },
    groupJsonArray(jsonArray, size) {
      // 解析 JSON 字符串为 JavaScript 数组
      const arr = jsonArray;

      return arr.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }

        acc[chunkIndex].push(curr);

        return acc;
      }, []);
    },
    getEveryDay(date, month) {
      //设置月份
      date.setMonth(month + 1);
      //设置一个月的某一天-这里设置为零则取到的日期中的天就会是当月的最后一天（比如，二月份就是28或29，其他月份就是30或31），方便下边的循环
      date.setDate(0);
      let dayArry = [];
      //获取月份的某一天
      let day = date.getDate();

      for (let i = 1; i <= day; i++) {
        date.setDate(i); //如果只获取当前选择月份的每一天，则不需要date.setDate(i)只需dayArry.push(i)即可，其中date.setDate(i)是设置当前月份的每一天

        dayArry.push({
          day: i > 9 ? i + "" : "0" + i,
          weekday:
            dayjs(date).startOf("day").valueOf() ===
            dayjs().startOf("day").valueOf()
              ? "今日"
              : this.getWeekday(date.getDay()),
          time: dayjs(date).startOf("day").format("YYYY-MM-DD"),
          maxDay: dayjs(date).endOf("month").date(),
        });
      }
      const temp = this.groupJsonArray(dayArry, 15);
      this.everyDay = dayArry;
      return temp;
    },
    getWeekday(day) {
      return ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][day];
    },
    changeCarousel(e) {
      this.swiperIndex = e;
    },
    changeSwiper(index) {
      if (index < 0) {
        index = this.dataList.length - 1;
      }
      if (index > this.dataList.length - 1) {
        index = 0;
      }
      this.$refs.carousel.setActiveItem(index);
    },
    changePerformanceCarousel(e) {
      this.dateSwiperIndex = e;
    },
    changePerformanceSwiper(index) {
      if (index < 0) {
        // 最早查询到2020年
        if (
          this.monthValue.valueOf() <=
          dayjs("2020-01-01").endOf("month").valueOf()
        ) {
          return;
        }
        this.monthValue = this.monthValue.subtract(1, "month");
        let date = new Date(this.monthValue),
          month = date.getMonth();
        const arr = this.getEveryDay(date, month);
        index = arr.length - 1;
        this.newEveryDay = [...arr, ...this.newEveryDay];
      } else if (index > this.newEveryDay.length - 1) {
        // 最多查询2个月后
        if (
          this.monthValue.valueOf() >
          dayjs().add(1, "month").endOf("month").valueOf()
        ) {
          return;
        }
        this.monthValue = this.monthValue.add(1, "month");
        let date = new Date(this.monthValue),
          month = date.getMonth();
        this.newEveryDay = this.newEveryDay.concat(
          this.getEveryDay(date, month)
        );
      } else if (this.newEveryDay[index]) {
        let firstItem = this.newEveryDay[index][0];
        this.monthValue = dayjs(firstItem.time).startOf("month");
      }
      this.dateSwiperIndex = index;
      // this.$refs.performanceCarousel.setActiveItem(index);
    },
    changeDayValue(record) {
      this.dateActiveValue = record;
      this.getList();
    },
    toBuy() {
      window.open("http://piao.bjry.com", "_blank");
    },
    async getList() {
      const res = await getRepertoirePage({
        pageNo: 1,
        pageSize: 20,
        condition: {
          performanceTime: this.dateActiveValue,
          status: 1,
          orderSign: 4,
          orderDirection: 0,
        },
      });
      const resTemp = this.groupJsonArray(res.data.data || [], 5);
      this.dataList = resTemp;
    },
    handleDetail(item) {
      window.open(`#/performance/detail/${item.id}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.performance-page {
  width: 100%;
  // min-height: 100vh;
  background-image: url("~@/assets/img/home/performance-bg.jpg");
  background-size: cover;
  padding-bottom: 4.0104vw;
  .xiuxi {
    height: 25.8854vw;
    text-align: center;
    line-height: 18.2292vw;
    font-size: 1.5625vw;
    font-weight: 600;
  }
  .operation {
    font-size: 1.0417vw;
    font-family: AppleSystemUIFont;
    color: #bf805f;
    line-height: 1.0417vw;
    padding-bottom: 1.0417vw;
    border-bottom: 0.1042vw solid #bf805f;
  }
  .content {
    .content-title-bg {
      .title {
        color: rgba(220, 188, 172, 0.8);
      }
      .tips {
        color: #21394b;
      }
    }
    .content-detail {
      width: 72.9167vw;
      margin: 0 auto;
      padding-top: 2.5521vw;
      position: relative;
      .content-header {
        position: relative;
        width: 65.625vw;
        margin: 0 auto;
        .performance-carousel {
          height: 4.1667vw;
        }
        .swiper-left {
          width: 2.0833vw;
          height: 2.0833vw;
          position: absolute;
          left: -3.9063vw;
          top: 1.0417vw;
          cursor: pointer;
        }
        .swiper-right {
          width: 2.0833vw;
          height: 2.0833vw;
          position: absolute;
          right: -3.9063vw;
          top: 1.0417vw;
          cursor: pointer;
        }
        .day-box {
          display: flex;
          justify-content: space-between;
          height: 3.8021vw;
          position: relative;
          .day-sub {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 3.8021vw;
            cursor: pointer;
            position: relative;
            &.active {
              .img {
                position: absolute;
                width: 2.5vw;
                height: 4.8958vw;
                left: -0.5208vw;
              }
              .day-value {
                color: #c7402f;
              }
              .day-dot {
                background: #ffffff;
              }
              .day-weekday {
                color: #c7402f;
              }
            }
            &::after {
              content: "";
              position: absolute;
              width: 2.0833vw;
              height: 0.1042vw;
              background: #d4c5b0;
              right: -2.6042vw;
              top: 50%;
            }
            &:last-child::after {
              display: none;
            }
            .day-value {
              font-size: 1.0938vw;
              font-family: AppleSystemUIFont;
              color: #212122;
              position: relative;
            }
            .day-dot {
              width: 0.3125vw;
              height: 0.3125vw;
              border-radius: 0.3125vw;
              background: #bf805f;
              position: relative;
            }
            .day-weekday {
              font-size: 0.7292vw;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #909399;
              position: relative;
            }
          }
        }
      }
      .content-swiper {
        margin-top: 2.1875vw;
        .artist-carousel {
          ::v-deep(.el-carousel__container) {
            height: 25.1563vw;
          }
          .artist-car-item {
            display: flex;
            .artist-card-item {
              width: 11.9792vw;
              box-sizing: border-box;
              margin-right: 1.5625vw;
              &:last-child {
                margin-right: 0;
              }
              .img-box {
                width: 11.9792vw;
                height: 17.7083vw;
                overflow: hidden;
                position: relative;
                box-shadow: 0 0.1563vw 0.5208vw 0 rgba(0, 0, 0, 0.2);
                cursor: pointer;

                .img {
                  width: 11.9792vw;
                  height: 17.7083vw;
                  border-radius: 0.4167vw;
                }
                .type {
                  position: absolute;
                  top: 1.0417vw;
                  left: 0;
                  height: 1.25vw;
                  line-height: 1.25vw;
                  background: #e3c297;
                  border-radius: 0 0.2083vw 0.2083vw 0;
                  padding: 0 0.4167vw;
                  font-size: 0.7292vw;
                  color: #21394b;
                }
                .buy-btn {
                  position: absolute;
                  bottom: -1.3542vw;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 4.1667vw;
                  height: 1.25vw;
                  line-height: 1.25vw;
                  text-align: center;
                  background: #c7402f;
                  border-radius: 0.2083vw;
                  font-size: 0.7292vw;
                  font-weight: 500;
                  color: #ffffff;
                  letter-spacing: 0.1042vw;
                  white-space: nowrap;
                  cursor: pointer;
                  transition: all 0.3s ease-out;
                }
              }
              .bot {
                text-align: left;

                .title {
                  font-size: 1.25vw;
                  font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
                  font-weight: 800;
                  color: #000000;
                  line-height: 1.3542vw;
                  letter-spacing: 0.1042vw;
                  margin: 0.5208vw 0 0.7813vw;
                  box-sizing: border-box;
                }
                .time {
                  font-size: 0.7292vw;
                  font-family: AppleSystemUIFont;
                  color: #717171;
                  line-height: 0.7292vw;
                  padding-top: 0.5208vw;
                  box-sizing: border-box;
                }
                .line {
                  font-size: 0.7292vw;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #21394b;
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.5729vw;
                  flex-shrink: 0;
                  .img {
                    margin-right: 0.4688vw;
                    width: 0.8333vw;
                    height: 0.7813vw;
                    ::v-deep(.el-image__inner) {
                      object-fit: contain !important;
                    }
                  }
                }
              }
              &:hover .img-box {
                .buy-btn {
                  bottom: 1.0417vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
